// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  APIURL: "https://api.mootori.com/",
  APIKEY: "000f3XG10GKSNOMOJQgwvxn30zPg8mi1X3csMp8XjLg=",
  USERSFILESURL: "https://mootori-s3-bucket-v2.s3.amazonaws.com/usersfiles",
  FILESURL: "https://mootori-s3-bucket-v2.s3.amazonaws.com/appfiles"

  // APIURL: "http://204.236.251.56/test/api/",
  // APIKEY: "000f3XG10GKSNOMOJQgwvxn30zPg8mi1X3csMp8XjLg=",
  // USERSFILESURL: "https://mootori-s3-bucket-v2.s3.amazonaws.com/usersfilestest",
  // FILESURL: "https://mootori-s3-bucket-v2.s3.amazonaws.com/appfilestest"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
