<div class="hero-section mb-3">
    <div class="hero-slider owl-carousel owl-theme">
        <div class="hero-single" style="padding: 0px;">
            <div class="container app-download">
                <div class="download-wrapper">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="download-content">
                                <div class="hero-content">
                                    <h6 class="hero-sub-title" data-animation="fadeInUp" data-delay=".25s">Welcome To
                                        Mootori!</h6>
                                    <h1 class="hero-title" data-animation="fadeInRight" data-delay=".50s">
                                        <!-- Best Way To Find Your <span>Dream</span> Car -->

                                        Everything <span style="white-space: nowrap;">a motorist</span> needs is a click
                                        away...
                                    </h1>
                                    <!-- <p data-animation="fadeInLeft" data-delay=".75s">
                                        There are many variations of passages orem psum available but the majority have
                                        suffered alteration in some form by injected humour.
                                    </p>
                                    <div class="hero-btn" data-animation="fadeInUp" data-delay="1s">
                                        <a href="#" class="theme-btn">About More<i class="fas fa-arrow-right-long"></i></a>
                                        <a href="#" class="theme-btn theme-btn2">Learn More<i
                                                class="fas fa-arrow-right-long"></i></a>
                                    </div> -->
                                </div>

                                <div class="site-heading mb-4">
                                    <span class="site-title-tagline justify-content-start">
                                        <i class="flaticon-drive"></i> Get Our App
                                    </span>
                                    <h2 class="site-title mb-10">Download <span>Mootori</span> App For Free</h2>
                                    <!-- <p>
                                    There are many variations of passages available but the majority have suffered
                                    in some form going to use a passage by injected humour.
                                </p> -->
                                </div>

                                <div class="download-btn">
                                    <a href="https://play.google.com/store/apps/details?id=com.mootori.app"
                                        target="_blank">
                                        <i class="fab fa-google-play"></i>
                                        <div class="download-btn-content">
                                            <span>Get It On</span>
                                            <strong>Google Play</strong>
                                        </div>
                                    </a>
                                    <a href="https://apps.apple.com/ae/app/mootori/id6448007817" target="_blank">
                                        <i class="fab fa-app-store"></i>
                                        <div class="download-btn-content">
                                            <span>Get It On</span>
                                            <strong>App Store</strong>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="download-img">
                        <img src="assets/img/mootori/01.png" alt="Mootori">
                    </div>
                </div>
            </div>
        </div>

        <div class="hero-single" style="padding: 0px;">
            <div class="container app-download">
                <div class="download-wrapper">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="download-content">
                                <div class="hero-content">
                                    <h6 class="hero-sub-title text-nowrap" data-animation="fadeInUp" data-delay=".25s">
                                        Mootori
                                        Garage Management System (GMS)!</h6>
                                    <h1 class="hero-title" data-animation="fadeInRight" data-delay=".50s"
                                        style="text-transform: none; width: 90%;">
                                        <!-- Best Way To Find Your <span>Dream</span> Car -->

                                        Your <span style="white-space: nowrap;">Garage</span> Needs in One System...
                                    </h1>
                                    <!-- <p data-animation="fadeInLeft" data-delay=".75s">
                                        There are many variations of passages orem psum available but the majority have
                                        suffered alteration in some form by injected humour.
                                    </p>
                                    <div class="hero-btn" data-animation="fadeInUp" data-delay="1s">
                                        <a href="#" class="theme-btn">About More<i class="fas fa-arrow-right-long"></i></a>
                                        <a href="#" class="theme-btn theme-btn2">Learn More<i
                                                class="fas fa-arrow-right-long"></i></a>
                                    </div> -->
                                </div>

                                <div class="site-heading mb-4">
                                    <!-- <span class="site-title-tagline justify-content-start">
                                        <i class="flaticon-drive"></i> Get Our App
                                    </span> -->
                                    <!-- <h2 class="site-title mb-10">Learn More and Join Now</h2> -->
                                    <!-- <p>
                                    There are many variations of passages available but the majority have suffered
                                    in some form going to use a passage by injected humour.
                                </p> -->
                                </div>

                                <div class="download-btn">
                                    <a href="gmsinfo" style="height: 66px;">
                                        <i class="fa-solid fa-info fs-5"></i>
                                        <div class="download-btn-content">
                                            <strong>Learn More</strong>
                                        </div>
                                    </a>

                                    <a href="https://gms.mootori.com/register/c" target="_blank" style="height: 66px;">
                                        <i class="fa-solid fa-briefcase fs-5"></i>
                                        <div class="download-btn-content">
                                            <strong>Join Now</strong>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="download-img-2">
                        <img src="assets/img/mootori/02.png" alt="Mootori">
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>

<div class="car-area grid" *ngIf="autos">
    <div class="container">
        <div class="row mt-1">
            <div class="col-lg-6 mx-auto">
                <div class="site-heading text-center mb-2">
                    <span class="site-title-tagline"><i class="flaticon-drive"></i> Recently Added Autos</span>
                    <!-- <h2 class="site-title">Best Dealers In <span>Your City</span></h2> -->
                    <div class="heading-divider"></div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 col-lg-4" *ngFor="let auto of autos">
                            <div class="car-item" (click)="addHits(auto)">
                                <div class="stm-badge-directory heading-font"
                                    *ngIf="auto.isPromoted || auto.isBusinessPromoted"> Promoted </div>

                                <div class="car-img">
                                    <span class="car-status status-1">{{auto.mileage > 0 ?"Used" : "New"}}</span>
                                    <img [src]="pageImages[auto.autoid + auto.autophotopath1]"
                                        alt="{{auto.makemodeltrim[0].autodisplayname}}" [attr.loading]="'lazy'">
                                    <div class="car-btns">
                                        <i class="fa-solid fa-user" style="color: #ec4d47; font-size: 30px;"
                                            *ngIf="!auto.businessid"></i>
                                        <img [src]="pageImages[auto.businessid + auto.business[0].businesslogopath]"
                                            style="height: 30px !important;" *ngIf="auto.businessid"
                                            alt="{{auto.business[0].businessname}}" [attr.loading]="'lazy'" />
                                    </div>
                                </div>
                                <div class="car-content">
                                    <div class="car-top" style="height: 40px;">
                                        <h4><a role="button" (click)="addHits(auto)"><img
                                                    [src]="pageImages[auto.makemodeltrim[0].makelogo]"
                                                    style="height: 15px !important; padding-right: 5px;"
                                                    alt="{{auto.makemodeltrim[0].autodisplayname}}" [attr.loading]="'lazy'">{{auto.makemodeltrim[0].autodisplayname}}</a>
                                        </h4>
                                        <!-- <div class="car-rate">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <span>5.0 (58.5k Review)</span>
                                </div> -->
                                    </div>
                                    <ul class="car-list" style="height: 65px;">
                                        <li>
                                            <!-- <i class="far fa-steering-wheel"></i> -->
                                            <img src="assets/img/mootori/gearservices.svg" style="height: 14px;"
                                                alt="gear services">
                                            {{ auto.transmission == 1
                                            ? "Automatic"
                                            : "Manual"}}
                                        </li>
                                        <li><i class="far fa-road"></i>{{auto.mileage | number}}km</li>
                                        <li><i class="fa-regular fa-calendar-days"></i>Model: {{auto.year}}</li>
                                        <li><i class="far fa-gas-pump"></i>{{engineType[auto.enginetype - 1]}}</li>
                                    </ul>
                                    <div class="car-footer">
                                        <span class="car-price">AED {{auto.price | number: '1.0-0'}}</span>
                                        <a role="button" class="theme-btn" (click)="addHits(auto)"><span
                                                class="far fa-eye"></span>Details</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center mt-4">
            <a href="/motors" class="theme-btn">Load More <i class="far fa-arrow-rotate-right"></i> </a>
        </div>
    </div>
</div>

<div class="row mt-1" *ngIf="promotedBusniss">
    <div class="col-lg-6 mx-auto">
        <div class="site-heading text-center mb-2">
            <span class="site-title-tagline"><i class="flaticon-drive"></i> Featured</span>
            <!-- <h2 class="site-title">Best Dealers In <span>Your City</span></h2> -->
            <div class="heading-divider"></div>
        </div>
    </div>
    <div class="col-lg-12">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-lg-3" *ngFor="let business of promotedBusniss"
                    (click)="geoToDetails($event,business)" role="button">
                    <div class="dealer-item wow fadeInUp" data-wow-delay=".25s">
                        <div class="dealer-img">
                            <!-- <span class="dealer-listing">{{business.autoscount}} Listing</span> -->
                            <img [src]="pageImages[business.businessid + business.businessimagepath1]"
                                alt="{{business.businessname}}" [attr.loading]="'lazy'">

                            <div class="car-btns"
                                *ngIf="currentUser && (currentUser.isadmin || currentUser.userid == 46)">
                                <!-- <a href="#"><i class="far fa-heart"></i></a> -->
                                <!-- <a href="business/edit/{{business.businessid}}"><i
                                        class="fa-solid fa-pen-to-square"></i></a> -->
                            </div>
                        </div>
                        <div class="dealer-content">
                            <h4><a
                                    href="business/{{getPageName(business)}}/details/{{business.businessid}}">{{business.businessname}}</a>
                            </h4>
                            <ul>
                                <!-- <li><a href="https://maps.google.com/maps?q={{business.businesslat}},{{business.businesslng}}"
                                        target="_blank"><i class="far fa-location-dot"></i>
                                        {{business.businessaddress}}</a>
                                </li> -->
                                <li class="d-location-item">
                                    <div>
                                        <i class="far fa-phone"></i> <a href="tel:+21236547898">+971
                                            {{business.businesscontactnumber}}</a>
                                    </div>
                                    <div name="location">
                                        <a href="https://maps.google.com/maps?q={{business.businesslat}},{{business.businesslng}}"
                                            target="_blank" name="location"><i class="far fa-location-dot"
                                                name="location"></i> {{business.businesscity}}</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

<div class="row mt-1" *ngIf="nearbyBusiness">
    <div class="col-lg-6 mx-auto">
        <div class="site-heading text-center mb-2">
            <span class="site-title-tagline"><i class="flaticon-drive"></i> Nearby Services at your Doorstep</span>
            <!-- <h2 class="site-title">Best Dealers In <span>Your City</span></h2> -->
            <div class="heading-divider"></div>
        </div>
    </div>
    <div class="col-lg-12">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-lg-3" *ngFor="let business of nearbyBusiness"
                    (click)="geoToDetails($event,business)" role="button">
                    <div class="dealer-item wow fadeInUp" data-wow-delay=".25s">
                        <div class="dealer-img">
                            <!-- <span class="dealer-listing">{{business.autoscount}} Listing</span> -->
                            <img [src]="pageImages[business.businessid + business.businessimagepath1]"
                                alt="{{business.businessname}}" [attr.loading]="'lazy'">

                            <div class="car-btns"
                                *ngIf="currentUser && (currentUser.isadmin || currentUser.userid == 46)">
                                <!-- <a href="#"><i class="far fa-heart"></i></a> -->
                                <!-- <a href="business/edit/{{business.businessid}}"><i
                                        class="fa-solid fa-pen-to-square"></i></a> -->
                            </div>
                        </div>
                        <div class="dealer-content">
                            <h4><a
                                    href="business/{{getPageName(business)}}/details/{{business.businessid}}">{{business.businessname}}</a>
                            </h4>
                            <ul>
                                <!-- <li><a href="https://maps.google.com/maps?q={{business.businesslat}},{{business.businesslng}}"
                                        target="_blank"><i class="far fa-location-dot"></i>
                                        {{business.businessaddress}}</a>
                                </li> -->
                                <li class="d-location-item">
                                    <div>
                                        <i class="far fa-phone"></i> <a href="tel:+21236547898">+971
                                            {{business.businesscontactnumber}}</a>
                                    </div>
                                    <div name="location">
                                        <a href="https://maps.google.com/maps?q={{business.businesslat}},{{business.businesslng}}"
                                            target="_blank" name="location"><i class="far fa-location-dot"
                                                name="location"></i> {{business.businesscity}}</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

<div class="row mt-1" *ngIf="nearbyBusinessService">
    <div class="col-lg-6 mx-auto">
        <div class="site-heading text-center mb-2">
            <span class="site-title-tagline"><i class="flaticon-drive"></i> Nearby Auto Services</span>
            <!-- <h2 class="site-title">Best Dealers In <span>Your City</span></h2> -->
            <div class="heading-divider"></div>
        </div>
    </div>
    <div class="col-lg-12">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-lg-3" *ngFor="let business of nearbyBusinessService"
                    (click)="geoToDetails($event,business)" role="button">
                    <div class="dealer-item wow fadeInUp" data-wow-delay=".25s">
                        <div class="dealer-img">
                            <!-- <span class="dealer-listing">{{business.autoscount}} Listing</span> -->
                            <img [src]="pageImages[business.businessid + business.businessimagepath1]"
                                alt="{{business.businessname}}" [attr.loading]="'lazy'">

                            <div class="car-btns"
                                *ngIf="currentUser && (currentUser.isadmin || currentUser.userid == 46)">
                                <!-- <a href="#"><i class="far fa-heart"></i></a> -->
                                <!-- <a href="business/edit/{{business.businessid}}"><i
                                        class="fa-solid fa-pen-to-square"></i></a> -->
                            </div>
                        </div>
                        <div class="dealer-content">
                            <h4><a
                                    href="business/{{getPageName(business)}}/details/{{business.businessid}}">{{business.businessname}}</a>
                            </h4>
                            <ul>
                                <!-- <li><a href="https://maps.google.com/maps?q={{business.businesslat}},{{business.businesslng}}"
                                        target="_blank"><i class="far fa-location-dot"></i>
                                        {{business.businessaddress}}</a>
                                </li> -->
                                <li class="d-location-item">
                                    <div>
                                        <i class="far fa-phone"></i> <a href="tel:+21236547898">+971
                                            {{business.businesscontactnumber}}</a>
                                    </div>
                                    <div name="location">
                                        <a href="https://maps.google.com/maps?q={{business.businesslat}},{{business.businesslng}}"
                                            target="_blank" name="location"><i class="far fa-location-dot"
                                                name="location"></i> {{business.businesscity}}</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="text-center mt-4" *ngIf="nearbyBusinessService">
    <a href="/business/services" class="theme-btn">Load More <i class="far fa-arrow-rotate-right"></i> </a>
</div>

<div class="row mt-1" *ngIf="nearbyBusinessSPare">
    <div class="col-lg-6 mx-auto">
        <div class="site-heading text-center mb-2">
            <span class="site-title-tagline"><i class="flaticon-drive"></i> Nearby Spare Parts Center</span>
            <!-- <h2 class="site-title">Best Dealers In <span>Your City</span></h2> -->
            <div class="heading-divider"></div>
        </div>
    </div>
    <div class="col-lg-12">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-lg-3" *ngFor="let business of nearbyBusinessSPare"
                    (click)="geoToDetails($event,business)" role="button">
                    <div class="dealer-item wow fadeInUp" data-wow-delay=".25s">
                        <div class="dealer-img">
                            <!-- <span class="dealer-listing">{{business.autoscount}} Listing</span> -->
                            <img [src]="pageImages[business.businessid + business.businessimagepath1]"
                                alt="{{business.businessname}}" [attr.loading]="'lazy'">

                            <div class="car-btns"
                                *ngIf="currentUser && (currentUser.isadmin || currentUser.userid == 46)">
                                <!-- <a href="#"><i class="far fa-heart"></i></a> -->
                                <!-- <a href="business/edit/{{business.businessid}}"><i
                                        class="fa-solid fa-pen-to-square"></i></a> -->
                            </div>
                        </div>
                        <div class="dealer-content">
                            <h4><a
                                    href="business/{{getPageName(business)}}/details/{{business.businessid}}">{{business.businessname}}</a>
                            </h4>
                            <ul>
                                <!-- <li><a href="https://maps.google.com/maps?q={{business.businesslat}},{{business.businesslng}}"
                                        target="_blank"><i class="far fa-location-dot"></i>
                                        {{business.businessaddress}}</a>
                                </li> -->
                                <li class="d-location-item">
                                    <div>
                                        <i class="far fa-phone"></i> <a href="tel:+21236547898">+971
                                            {{business.businesscontactnumber}}</a>
                                    </div>
                                    <div name="location">
                                        <a href="https://maps.google.com/maps?q={{business.businesslat}},{{business.businesslng}}"
                                            target="_blank" name="location"><i class="far fa-location-dot"
                                                name="location"></i> {{business.businesscity}}</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="text-center mt-4" *ngIf="nearbyBusinessSPare">
    <a href="/business/spareparts" class="theme-btn">Load More <i class="far fa-arrow-rotate-right"></i> </a>
</div>

<div class="row mt-1" *ngIf="nearbyBusinessShowroom">
    <div class="col-lg-6 mx-auto">
        <div class="site-heading text-center mb-2">
            <span class="site-title-tagline"><i class="flaticon-drive"></i> Nearby Auto Showrooms</span>
            <!-- <h2 class="site-title">Best Dealers In <span>Your City</span></h2> -->
            <div class="heading-divider"></div>
        </div>
    </div>

    <div class="col-lg-12">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-lg-3" *ngFor="let business of nearbyBusinessShowroom"
                    (click)="geoToDetails($event, business)" role="button">
                    <div class="dealer-item wow fadeInUp" data-wow-delay=".25s">
                        <div class="dealer-img">
                            <span class="dealer-listing">{{business.autoscount}} Listing</span>
                            <img [src]="pageImages[business.businessid + business.businessimagepath1]"
                                alt="{{business.businessname}}" [attr.loading]="'lazy'">
                        </div>
                        <div class="dealer-content">
                            <h4><a
                                    href="business/{{getPageName(business)}}/details/{{business.businessid}}">{{business.businessname}}</a>
                            </h4>
                            <ul>
                                <!-- <li><i class="far fa-location-dot"></i> {{business.businessaddress}}</li> -->
                                <li class="d-location-item">
                                    <div>
                                        <i class="far fa-phone"></i> <a href="tel:+21236547898">+971
                                            {{business.businesscontactnumber}}</a>
                                    </div>
                                    <div name="location">
                                        <a href="https://maps.google.com/maps?q={{business.businesslat}},{{business.businesslng}}"
                                            target="_blank" name="location"><i class="far fa-location-dot"
                                                name="location"></i> {{business.businesscity}}</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="text-center mt-4" *ngIf="nearbyBusinessShowroom">
    <a href="/business/showrooms" class="theme-btn">Load More <i class="far fa-arrow-rotate-right"></i> </a>
</div>