import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {
  @Input() sendTo: string | null = null;

  @Output('submitOTP') submitOTP = new EventEmitter();

  @Output('resendOTP') resendOTP = new EventEmitter();

  isMobileOTP: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.isMobileOTP = this.sendTo != null && this.sendTo!.indexOf("@") > 0;

    (<HTMLInputElement>document.querySelector("[name='code_1']")).focus();
  }

  elementTab(name: string) {
    if (name.indexOf("code_") >= 0) {
      var otp = (<HTMLInputElement>document.querySelector("[name='" + name + "']"));

      (<HTMLInputElement>otp.nextElementSibling).focus();
    }
  }

  submit() {
    this.submitOTP.emit();

    return false;
  }

  newOTP() {
    (<HTMLInputElement>document.querySelector("[name='code_1']")).value = '';
    (<HTMLInputElement>document.querySelector("[name='code_2']")).value = '';
    (<HTMLInputElement>document.querySelector("[name='code_3']")).value = '';
    (<HTMLInputElement>document.querySelector("[name='code_4']")).value = '';
    (<HTMLInputElement>document.querySelector("[name='code_5']")).value = '';
    (<HTMLInputElement>document.querySelector("[name='code_6']")).value = '';

    this.resendOTP.emit();

    return false;
  }

}
