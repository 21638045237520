import { Component, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

import { AutoService } from 'src/services/auto.service';
import { DynamicScriptLoaderService } from 'src/services/dynamic-script-loader.service';

import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { EmailService } from 'src/services/email.service';
import { DatePipe } from '@angular/common';
import { MetadataService } from 'src/services/metadata.service';
import { FileUploadService } from 'src/services/file-upload.service';


declare var jQuery: any;

declare var watermark: any;

declare var google: any;

@Component({
  selector: 'app-auto-details',
  templateUrl: './auto-details.component.html',
  styleUrls: ['./auto-details.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AutoDetailsComponent implements OnInit {
  currentUser: any = null;

  autoid: string | null = null;
  selectedAuto: any | null = null;

  autoImages: any[] = [];
  autoImagesURLs: any[] = [];

  imagesKey: string[] = [];
  pageImages: any = {};

  similarAuto: any[] | null = null;

  USERSFILESURL: string = environment.USERSFILESURL;
  FILESURL: string = environment.FILESURL;

  colorList = [
    "#000000", // Colors.black
    "#0000FF", // Colors.blue
    "#A52A2A", // Colors.brown
    "", // Colors.amber
    "#808080", // Colors.grey
    "#FFA500", // Colors.orange
    "#008000", // Colors.green
    "#8E35EF", // Colors.purple
    "#FF0000", // Colors.red
    "#00827F", // Colors.teal
    "#FFFFFF", // Colors.white
    "#FFFF00", // Colors.yellow
  ];

  colorList2 = [
    "Black",
    "Blue",
    "Brown",
    "Amber",
    "Grey",
    "Orange",
    "Green",
    "Purple",
    "Red",
    "Teal",
    "White",
    "Yellow",
  ];

  carSpe = [
    "American",
    "GCC/UAE Purchased",
    "Other Europian Country",
    "i Dont know"
  ];

  bodycondition = [
    "Perfect inside and out",
    "No accidents, very few faults",
    "A bit of wear and tear, all repaired",
    "Normal wear and teal, a few issues",
    "Lots of wear and tear with the body",
  ];

  mechanicalcondition = [
    "Perfect",
    "Minor Faults, all fixed",
    "Major Faults, all fixed",
    "Major faults fixed, few remains",
    "Ongoing minor or major faults",
  ];

  warranty = [
    "Yes",
    "No",
    "Does not apply",
  ];

  steeringside = ["Left", "Right", "Does not apply"];

  engineType = ["Petrol", "Diesel", "Hybrid", "Electric"];

  randomQueryParam = `?v=${Math.random().toString(36).substring(7)}`;

  constructor(@Optional() private metadataService: MetadataService, private titleService: Title, private route: ActivatedRoute, private autoService: AutoService, private fileUploadService: FileUploadService, private emailService: EmailService, private dynamicScriptLoader: DynamicScriptLoaderService, private sanitizer: DomSanitizer, private meta: Meta) {
    let id = this.route.snapshot.paramMap.get('id');

    if (id)
      this.autoid = id;

    this.currentUser = JSON.parse(localStorage.getItem('user')!);

    dynamicScriptLoader.load("google");
  }


  async getBase64ImageFromUrl(imageUrl: string) {
    var res = await fetch(imageUrl, {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*"
      }
    });
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

  async ngOnInit(): Promise<void> {

    document.querySelector(".preloader")?.removeAttribute("style");

    this.selectedAuto = await this.autoService.getAutoAsync({ autoid: this.autoid! });

    if (this.selectedAuto) {
      try {
        // this.meta.updateTag({ property: 'og:image', content: this.USERSFILESURL + '/' + this.selectedAuto.autoid + this.selectedAuto["autophotopath1"] + this.randomQueryParam });
        // this.meta.updateTag({ property: 'og:title', content: this.selectedAuto.makemodeltrim[0].autodisplayname });
        // this.meta.updateTag({ property: 'og:description', content: this.selectedAuto.autodescription });
        // this.meta.updateTag({ property: 'og:url', content: window.location.href });

        this.titleService.setTitle(this.selectedAuto.makemodeltrim[0].autodisplayname + " - Mootori");

        if (this.metadataService) {
          this.metadataService.updateMetadata({
            title: this.selectedAuto.makemodeltrim[0].autodisplayname,
            description: this.selectedAuto.autodescription,
            imageUrl: await this.getImageUrl("usersfiles/" + this.selectedAuto.autoid + this.selectedAuto["autophotopath1"]) //this.USERSFILESURL + '/' + this.selectedAuto.autoid + this.selectedAuto["autophotopath1"] + this.randomQueryParam
          });
        }

        await this.initMap(this.selectedAuto.autolat, this.selectedAuto.autolng, this.selectedAuto.city);

        this.similarAuto = await this.autoService.getSimilarAutoAsync(this.selectedAuto.autoid, this.selectedAuto.makemodeltrim[0].make, this.selectedAuto.vehicletype);

        if (this.similarAuto && this.similarAuto.length > 0) {
          let promotedAuto: any[] = this.similarAuto.filter((a) => a.isPromoted);
          promotedAuto = promotedAuto.sort((a, b) => b.autoid - a.autoid);

          let otherAuto: any[] = this.similarAuto.filter((a) => !a.isPromoted);
          otherAuto = otherAuto.sort((a, b) => b.autoid - a.autoid);

          this.similarAuto = promotedAuto.concat(otherAuto);

          this.imagesKey = [...this.imagesKey, ...this.similarAuto.map(a => "usersfiles/" + a.autoid + a.autophotopath1)]
          this.imagesKey = [...this.imagesKey, ...this.similarAuto.map(a => "appfiles/brandslogo/" + a.makemodeltrim[0].makelogo)]
        }
      } catch (error) {

      }

      for (let i = 1; i < 11; i++) {
        if (this.selectedAuto["autophotopath" + i.toString()]) {
          this.autoImages.push(this.selectedAuto["autophotopath" + i.toString()]);
        }
      }

      this.imagesKey = [...this.imagesKey, ...this.autoImages.map(i => "usersfiles/" + this.selectedAuto.autoid + i)];

      if (this.selectedAuto.business) {
        this.imagesKey = [...this.imagesKey, "usersfiles/" + this.selectedAuto.businessid + this.selectedAuto.business[0].businesslogopath]
      }

      this.pageImages = await this.fileUploadService.getBulkPresignedUrls(this.imagesKey);

      // if (this.autoImages.length > 0) {
      //   this.autoImages.forEach(p => {
      //     let path = this.USERSFILESURL + '/' + this.selectedAuto.autoid + p;

      //     const img = new Image();
      //     img.src = path;
      //     let canvas = document.createElement('canvas');

      //     const watermark = new Image(20, 50);
      //     watermark.src = 'assets/img/logo/mootori_Red.png';

      //     let width = document.getElementById('watermarkSlider')?.clientWidth;

      //     img.onload = () => {
      //       let ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

      //       const ratio = img.width / img.height;
      //       const newWidth = width!;
      //       const newHeight = 410; //newWidth / ratio;
      //       canvas.width = newWidth;
      //       canvas.height = newHeight;
      //       ctx!.drawImage(img, 0, 0, newWidth, newHeight);

      //       const logo = new Image();
      //       logo.src = 'assets/img/logo/mootori_Red.png';


      //       logo.style.opacity = "0.5";
      //       logo.onload = () => {
      //         const logoWidth = newWidth / 4;
      //         const logoHeight = logoWidth / logo.width * logo.height;
      //         ctx!.drawImage(logo, newWidth - logoWidth - 10, newHeight - logoHeight - 10, logoWidth, logoHeight);

      //         ctx!.getImageData
      //       };

      //       // ctx!.font = '24px Arial';
      //       // ctx!.fillStyle = 'rgba(255, 255, 255, 0.5)';

      //       // ctx!.fillText('watermark text', canvas.width - 200, canvas.height - 50);
      //     };

      //     let url = canvas.toDataURL();

      //     this.autoImagesURLs.push(url);

      //     // const img2 = new Image();
      //     // img2.src = this.sanitizer.bypassSecurityTrustUrl(url) as any;



      //     let li = document.createElement('li');
      //     li.setAttribute('data-thumb', url)
      //     li.appendChild(canvas);

      //     document.getElementById('watermarkSlider')!.appendChild(li);

      //     // path = 'assets/img/car/01.jpg';

      //     // this.getBase64ImageFromUrl(path)
      //     //   .then(result => {


      //     //     watermark([result, 'assets/img/logo/mootori_Red.png'])
      //     //       .image(watermark.image.center(0.5))
      //     //       .then((img: any) => {
      //     //         document.getElementById('watermarkSlider')!.appendChild(img);
      //     //       });
      //     //   })
      //     //   .catch(err => console.error(err));


      //   })
      // }

      await this.autoService.addHitsAsync({
        "businessid": null,
        "autoid": this.selectedAuto.autoid,
        "userid": this.currentUser != null ? this.currentUser.userid : null,
        "hitedby": 2
      });
    }

    this.dynamicScriptLoader.load("slider");

    setTimeout(() => {
      this.dynamicScriptLoader.load("main");
    }, 50);

    // setTimeout(() => {
    //   this.replaceToCanvas('.slides li img');

    //   // this.replaceToCanvas('.flex-control-thumbs li img')
    // }, 50);

    setTimeout(() => {
      // this.replaceToCanvas('.flex-control-thumbs li img')

      // this.replaceToCanvas('.car-img img');

      document.querySelector(".preloader")?.setAttribute("style", "display: none;");
    }, 300);
  }

  replaceToCanvas(images: string) {
    let imgs = document.querySelectorAll(images);

    // console.log(imgs);

    for (let i = 0; i < imgs.length; i++) {
      const img = imgs.item(i) as HTMLImageElement;

      const parent = img!.parentNode as HTMLElement;

      const newWidth = parent.clientWidth;
      const newHeight = parent.clientHeight;

      let canvas = document.createElement('canvas');
      let ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

      canvas.width = newWidth;
      canvas.height = newHeight;
      ctx!.drawImage(img, 0, 0, newWidth, newHeight);

      const logo = new Image();
      logo.src = 'assets/img/logo/mootori_Red.png';


      logo.style.opacity = "0.5";
      logo.onload = () => {
        const logoWidth = newWidth / 4;
        const logoHeight = logoWidth / logo.width * logo.height;
        ctx!.drawImage(logo, newWidth - logoWidth - 10, newHeight - logoHeight - 10, logoWidth, logoHeight);

        ctx!.getImageData
      };

      img!.remove();

      //parent.removeAttribute("data-thumb");

      parent.appendChild(canvas);
    }
  }

  async addHits(auto: any) {
    // await this.autoService.addHitsAsync({
    //   "businessid": null,
    //   "autoid": auto.autoid,
    //   "userid": null
    // });

    window.location.href = "auto/details/" + auto.autoid;
  }

  async sendMessage() {
    let nameCtrl = document.getElementById("txt_name") as HTMLInputElement;
    let emailCtrl = document.getElementById("txt_email") as HTMLInputElement;
    let messageCtrl = document.getElementById("txt_message") as HTMLInputElement;

    if (nameCtrl.value != "" && emailCtrl.value != "" && messageCtrl.value != "") {

      let name = this.selectedAuto.business ? this.selectedAuto.business[0].businessname :
        this.selectedAuto.user[0].username;

      let email = this.selectedAuto.business ? this.selectedAuto.business[0].businessemail :
        this.selectedAuto.user[0].useremail;

      let date = new DatePipe("en-US").transform(this.selectedAuto.createdatetime, 'EEE, MMM dd yyyy');

      let subject = "Inquiry for " + this.selectedAuto.makemodeltrim[0].make + " " + this.selectedAuto.makemodeltrim[0].model + " " + this.selectedAuto.year + " - " + new DatePipe("en-US").transform(new Date(), 'EEE, MMM dd yyyy');

      try {
        await this.emailService.sendMessageAsync(email, subject, "u_auto_sale", {
          "name": name,
          "brand": this.selectedAuto.makemodeltrim[0].make,
          "model": this.selectedAuto.makemodeltrim[0].model,
          "year": this.selectedAuto.year,
          "date": date,
          "bname": nameCtrl.value,
          "bemail": emailCtrl.value,
          "bmessage": messageCtrl.value,
          "link": window.location.href
        });
      } catch (error) {

      }


      nameCtrl.value = "";
      emailCtrl.value = "";
      messageCtrl.value = "";
    } else {
      alert("all fields are requerid");
    }
  }

  async initMap(lat: any, lng: any, label: any) {
    // Request needed libraries.
    const { Map } = await google.maps.importLibrary("maps");
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
    const map = new Map(document.getElementById("map"), {
      center: { lat: lat, lng: lng },
      zoom: 14,
      mapId: "37c3af23858be656",
    });
    const priceTag = document.createElement("div");

    if (priceTag) {
      priceTag.className = "price-tag";
      priceTag.textContent = label;
    }

    const marker = new AdvancedMarkerElement({
      map,
      position: { lat: lat, lng: lng },
      content: priceTag,
    });

    this.codeLatLng(lat, lng);
  }

  codeLatLng(lat: any, lng: any) {
    var latlng = new google.maps.LatLng(lat, lng);
    let city: any;
    let geocoder = new google.maps.Geocoder;

    geocoder.geocode({ 'latLng': latlng }, function (results: any, status: any) {
      if (status == google.maps.GeocoderStatus.OK) {
        console.log(results);
        if (results[1]) {
          //formatted address
          // alert(results[0].formatted_address)
          //find country name
          for (var i = 0; i < results[0].address_components.length; i++) {
            for (var b = 0; b < results[0].address_components[i].types.length; b++) {

              //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
              if (results[0].address_components[i].types[b] == "administrative_area_level_1") {
                //this is the object you are looking for
                city = results[0].address_components[i];
                break;
              }
            }
          }
          //city data
          // alert(city.short_name + " " + city.long_name);

          const priceTag = <HTMLDivElement>document.querySelector(".price-tag");
          if (priceTag)
            priceTag.textContent = city.long_name;
        } else {
          const priceTag = <HTMLDivElement>document.querySelector(".price-tag");
          if (priceTag)
            priceTag.textContent = `${lat}, ${lng}`;
        }
      } else {
        const priceTag = <HTMLDivElement>document.querySelector(".price-tag");
        if (priceTag)
          priceTag.textContent = `${lat}, ${lng}`;
      }
    });
  }

  async getImageUrl(path: any) {
    return await this.fileUploadService.getPresignedUrl(path);
  }

  mapURL() {
    return this.sanitizer.bypassSecurityTrustResourceUrl("https://maps.google.com?q=" + this.selectedAuto.autolat + "," + this.selectedAuto.autolng + "&hl=en&z=14&output=embed");
  }

  safURL(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

}
