<div class="blog-single-area pt-20 pb-120" *ngIf="autoServices">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="blog-single-wrapper">
                    <div class="blog-single-content">

                        <div class="car-single-slider">
                            <div class="item-gallery">
                                <div class="flexslider-thumbnails">
                                    <ul class="slides">
                                        <li *ngFor="let path of autoServicesImages"
                                            [attr.data-thumb]="pageImages[autoServices.businessid + path]">
                                            <img [src]="pageImages[autoServices.businessid + path]"
                                                alt="{{autoServices.businessname}}" [attr.loading]="'lazy'">
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="blog-info">
                            <!-- <div class="blog-meta">
                                <div class="blog-meta-left">
                                    <ul>
                                        <li><i class="far fa-user"></i><a href="#">Jean R Gunter</a></li>
                                        <li><i class="far fa-comments"></i>3.2k Comments</li>
                                        <li><i class="far fa-thumbs-up"></i>1.4k Like</li>
                                    </ul>
                                </div>
                                <div class="blog-meta-right">
                                    <a href="#" class="share-btn"><i class="far fa-share-alt"></i>Share</a>
                                </div>
                            </div> -->
                            <div class="blog-details">
                                <div class="blog-details-title-container">
                                    <h3 class="blog-details-title mb-20"><img
                                            [src]="pageImages[autoServices.businessid + autoServices.businesslogopath]"
                                            alt="{{autoServices.businessname}}" style="height: 30px;" [attr.loading]="'lazy'"> {{autoServices.businessname}}</h3>

                                    <span><i class="far fa-eye"></i> Views: {{autoServices.hits + 1}}</span>
                                </div>

                                <p class="mb-10">
                                    {{autoServices.businessdescription}}
                                </p>

                            </div>

                        </div>

                        <hr />

                        <h4 class="widget-title">Main Services</h4>
                        <div class="row bc" *ngIf="autoServices.businesscategories">
                            <div class="col-3" *ngFor="let category of autoServices.businesscategories">
                                <div class="category-item wow fadeInUp" data-wow-delay=".25s"
                                    style="visibility: visible; animation-delay: 0.25s; animation-name: fadeInUp;">
                                    <div class="category-img">
                                        <img [src]="pageImages[getCategoryImageName(category.categoryiconpath, 'red')]"
                                            alt="{{category.categoryname}}" [attr.loading]="'lazy'">
                                    </div>
                                    <h5>{{category.categoryname}}</h5>
                                </div>
                            </div>
                        </div>

                        <h4 class="widget-title">Location</h4>
                        <div class="car-single-map">
                            <div class="contact-map">
                                <!-- <iframe [src]="mapURL()" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->

                                <div id="map"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <aside class="sidebar">
                    <div class="widget">
                        <h5 class="widget-title">Contact</h5>
                        <div class="car-single-author">

                            <div class="car-single-author-content">
                                <span><i class="far fa-envelopes"></i> {{autoServices.businessemail}}</span>

                                <br>
                                <span><i class="far fa-phone-volume"></i>
                                    +971{{autoServices.businesscontactnumber}}</span>

                                <br *ngIf="autoServices.businessmobilenumber">
                                <span *ngIf="autoServices.businessmobilenumber"><i class="fa-solid fa-mobile-retro"></i>
                                    +971{{autoServices.businessmobilenumber}}</span>

                                <br *ngIf="autoServices.businesssecondarymobilenumber">
                                <span *ngIf="autoServices.businesssecondarymobilenumber"><i
                                        class="fa-solid fa-mobile-retro"></i>
                                    +971{{autoServices.businesssecondarymobilenumber}}</span>

                                <br *ngIf="autoServices.businesswhatsappnumber">
                                <span *ngIf="autoServices.businesswhatsappnumber"><i class="fa-brands fa-whatsapp"></i>
                                    +971{{autoServices.businesswhatsappnumber}}</span>
                                <!-- <div class="car-single-author-social">
                                    <a href="#"><i class="fab fa-facebook"></i></a>
                                    <a href="#"><i class="fab fa-instagram"></i></a>
                                    <a href="#"><i class="fab fa-twitter"></i></a>
                                    <a href="#"><i class="fab fa-linkedin"></i></a>
                                </div> -->
                            </div>
                        </div>
                    </div>

                    <div class="widget brands" *ngIf="autoServices && autoServices.businessbrands">
                        <h5 class="widget-title">Brands</h5>
                        <div class="row">
                            <div class="col-6" *ngFor="let brand of autoServices.businessbrands">
                                <div class="category-item wow fadeInUp" data-wow-delay=".25s"
                                    style="visibility: visible; animation-delay: 0.25s; animation-name: fadeInUp;">
                                    <div class="category-img">
                                        <img [src]="pageImages[brand.brandlogopath]" alt="{{brand.brandname}}" [attr.loading]="'lazy'">
                                    </div>
                                    <h5>{{brand.brandname}}</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="widget" *ngIf="autoServices && autoServices.businessdeliverychannel">
                        <h5 class="widget-title">Delivery Channel</h5>
                        <ul class="about-list list-unstyled">
                            <li
                                *ngIf="autoServices.businessdeliverychannel == 1 || autoServices.businessdeliverychannel == 3">
                                Doorstep</li>
                            <li
                                *ngIf="autoServices.businessdeliverychannel == 2 || autoServices.businessdeliverychannel == 3">
                                On Premises</li>
                        </ul>
                    </div>

                    <div class="widget">
                        <h5 class="widget-title">Engine Type</h5>
                        <ul class="about-list list-unstyled">
                            <li *ngIf="autoServices.EnginePetrol == 1">
                                Petrol</li>
                            <li *ngIf="autoServices.EngineDiesel == 1">
                                Diesel</li>
                            <li *ngIf="autoServices.EngineHybrid == 1">
                                Hybrid</li>
                            <li *ngIf="autoServices.EngineElectrical == 1">
                                Electrical</li>
                        </ul>
                    </div>

                    <div class="widget wh" *ngIf="autoServices && autoServices.businessworkinghours">
                        <h5 class="widget-title">Working Hours</h5>
                        <ul>
                            <li *ngFor="let wh of autoServices.businessworkinghours; let i = index;">

                                <span class="badge text-bg-success" style="width: 40px;"
                                    *ngIf="wh.isweekend == 0">{{getDayName(i)}}</span>
                                <span class="badge text-bg-danger" style="width: 40px;"
                                    *ngIf="wh.isweekend == 1">{{getDayName(i)}}</span>
                                <div class="d-inline-block ms-2"
                                    *ngIf="(wh.starttime != '00:00:00' && wh.closetime != '00:00:00') || (wh.starttime != '00:00:00' && wh.closetime == '00:00:00')">
                                    <span>
                                        <i class="far fa-alarm-clock me-1" style="color: var(--theme-color);"></i>
                                        ({{(wh.isweekend == 0) ? (timeFormat(wh.starttime) + " - " +
                                        timeFormat(wh.closetime)) : "Closed"}})
                                    </span>
                                    <span class="d-block" style="font-size: 10px; color: orange;"
                                        *ngIf="wh.breakstarttime && wh.breakendtime">
                                        Break ({{timeFormat(wh.breakstarttime) + " - " +
                                        timeFormat(wh.breakendtime)}})
                                    </span>
                                </div>

                                <div class="d-inline-block ms-2"
                                    *ngIf="wh.starttime == '00:00:00' &&wh.closetime == '00:00:00'">
                                    <span>
                                        <i class="far fa-alarm-clock me-1" style="color: var(--theme-color);"></i>
                                        Open 24 hours
                                    </span>
                                </div>

                            </li>
                        </ul>

                    </div>

                </aside>
            </div>
        </div>
    </div>
</div>