<div class="car-item-single bg pt-25" *ngIf="selectedAuto">
    <div class="container">
        <div class="car-single-wrapper">
            <div class="row">
                <div class="col-lg-8">
                    <div class="car-single-details">
                        <div class="car-single-widget">
                            <div class="car-single-top">
                                <span class="car-status status-1">{{selectedAuto.mileage > 0 ? "Used" :
                                    "Brand New"}}</span>
                                <h3 class="car-single-title">{{selectedAuto.makemodeltrim[0].autodisplayname}}</h3>
                                <ul class="car-single-meta">
                                    <li><i class="flaticon-drive"></i> Year: {{selectedAuto.year}}</li>
                                    <li><i class="far fa-clock"></i> Listed On:
                                        {{selectedAuto.createdatetime | date: "EEE, MMM dd yyyy"}}</li>
                                    <li><i class="far fa-eye"></i> Views: {{selectedAuto.hits + 1}}</li>
                                </ul>
                            </div>
                            <div class="car-single-slider">
                                <div class="item-gallery">

                                    <!-- <div id="slider" class="flexslider">
                                        <ul class="slides">
                                            <li *ngFor="let path of autoImages">
                                                <img [src]="USERSFILESURL + '/' + selectedAuto.autoid + path" alt="#">
                                            </li>
                                           
                                        </ul>
                                    </div>
                                    <div id="carousel" class="flexslider">
                                        <ul class="slides">
                                            <li *ngFor="let path of autoImages">
                                                <img [src]="USERSFILESURL + '/' + selectedAuto.autoid + path" alt="#">
                                            </li>
                                            
                                        </ul>
                                    </div> -->



                                    <div class="flexslider-thumbnails">
                                        <ul class="slides" id="watermarkSlider">
                                            <li *ngFor="let path of autoImages"
                                                [attr.data-thumb]="pageImages[selectedAuto.autoid + path]">
                                                <img [src]="pageImages[selectedAuto.autoid + path]"
                                                    alt="{{selectedAuto.makemodeltrim[0].autodisplayname}}"
                                                    [attr.loading]="'lazy'">
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="car-single-widget">
                            <h4 class="mb-4">Key Information</h4>
                            <div class="car-key-info">
                                <div class="row">
                                    <div class="col-lg-3 col-md-4 col-6">
                                        <div class="car-key-item">
                                            <div class="car-key-icon">
                                                <i class="flaticon-drive"></i>
                                            </div>
                                            <div class="car-key-content">
                                                <span>Body Type</span>
                                                <h6>{{selectedAuto.bodystyle}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-6">
                                        <div class="car-key-item">
                                            <div class="car-key-icon">
                                                <i class="flaticon-drive"></i>
                                            </div>
                                            <div class="car-key-content">
                                                <span>Condition</span>
                                                <h6>Brand {{selectedAuto.mileage > 0 ? "Used" : "New"}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-6">
                                        <div class="car-key-item">
                                            <div class="car-key-icon">
                                                <i class="flaticon-speedometer"></i>
                                            </div>
                                            <div class="car-key-content">
                                                <span>Mileage</span>
                                                <h6>{{selectedAuto.mileage | number}} (Km)</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-6">
                                        <div class="car-key-item">
                                            <div class="car-key-icon">
                                                <i class="flaticon-settings"></i>
                                            </div>
                                            <div class="car-key-content">
                                                <span>Transmission</span>
                                                <h6>{{selectedAuto.transmission == 1 ? "Automatic" : "Manual"}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-6">
                                        <div class="car-key-item">
                                            <div class="car-key-icon">
                                                <i class="flaticon-drive"></i>
                                            </div>
                                            <div class="car-key-content">
                                                <span>Year</span>
                                                <h6>{{selectedAuto.year}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-6">
                                        <div class="car-key-item">
                                            <div class="car-key-icon">
                                                <i class="flaticon-gas-station"></i>
                                            </div>
                                            <div class="car-key-content">
                                                <span>Fuel Type</span>
                                                <h6>{{engineType[selectedAuto.enginetype - 1]}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-6">
                                        <div class="car-key-item">
                                            <div class="car-key-icon">
                                                <i class="flaticon-drive"></i>
                                            </div>
                                            <div class="car-key-content">
                                                <span>Color</span>
                                                <h6>{{colorList2[selectedAuto.autocolour - 1]}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-6">
                                        <div class="car-key-item">
                                            <div class="car-key-icon">
                                                <i class="flaticon-drive"></i>
                                            </div>
                                            <div class="car-key-content">
                                                <span>Doors</span>
                                                <h6>{{selectedAuto.doors}} Doors</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-6">
                                        <div class="car-key-item">
                                            <div class="car-key-icon">
                                                <i class="flaticon-drive"></i>
                                            </div>
                                            <div class="car-key-content">
                                                <span>Cylinders</span>
                                                <h6>{{selectedAuto.numberofcylinders}}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-lg-3 col-md-4 col-6">
                                        <div class="car-key-item">
                                            <div class="car-key-icon">
                                                <i class="flaticon-drive"></i>
                                            </div>
                                            <div class="car-key-content">
                                                <span>Engine Size</span>
                                                <h6></h6>
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-lg-3 col-md-4 col-6">
                                        <div class="car-key-item">
                                            <div class="car-key-icon">
                                                <i class="flaticon-drive"></i>
                                            </div>
                                            <div class="car-key-content">
                                                <span>VIN</span>
                                                <h6>{{selectedAuto.chassisnumber}}</h6>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="car-single-widget">
                            <div class="car-single-overview">
                                <h4 class="mb-3">Description</h4>
                                <div class="mb-4">
                                    <p>{{selectedAuto.autodescription}}</p>
                                </div>
                                <h4 class="mb-3" *ngIf="selectedAuto.autofeatures">Car Features</h4>
                                <div class="row mb-3" *ngIf="selectedAuto?.autofeatures">

                                    <div class="col-4" *ngFor="let af of selectedAuto.autofeatures">
                                        <div class="border rounded m-1 p-2 vehicle-type text-center bg-primary-subtle">
                                            <img [src]="'assets/img/mootori/autofeatures/' + af.featureimagepath.replace('.svg', ' - red.svg')"
                                                height="70" width="80" alt="{{af.featurename}}">
                                            <span class="d-block text-nowrap mt-1">{{af.featurename}}</span>
                                        </div>
                                    </div>
                                </div>
                                <h4 class="mb-4">Additional Details</h4>
                                <div class="mb-4">
                                    <ul class="car-single-list">
                                        <li><i class="far fa-check-circle"></i>
                                            Body Condition: {{bodycondition[selectedAuto.bodycondition - 1]}} </li>
                                        <li><i class="far fa-check-circle"></i>
                                            Mechanical Condition: {{mechanicalcondition[selectedAuto.mechanicalcondition
                                            - 1]}}</li>
                                        <li><i class="far fa-check-circle"></i> Warranty:
                                            {{warranty[selectedAuto.warranty - 1]}}</li>
                                    </ul>
                                </div>
                                <h4 class="mb-4">Location</h4>
                                <div class="car-single-map">
                                    <div class="contact-map">
                                        <!-- <iframe [src]="mapURL()" style="border:0;" allowfullscreen=""
                                            loading="lazy"></iframe> -->

                                        <div id="map"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="car-single-widget">
                        <div class="stm-badge-directory heading-font" *ngIf="selectedAuto.isPromoted"> Promoted </div>

                        <h4 class="car-single-price">AED {{selectedAuto.price | number: '1.0-0'}}</h4>
                        <ul class="car-single-meta">
                            <!-- <li><i class="fa-regular fa-gauge-high"></i> 50k Miles</li> -->
                            <li><i class="far fa-location-dot"></i> {{selectedAuto.autoaddress}}</li>
                        </ul>
                    </div>
                    <div class="car-single-widget">
                        <div class="car-single-author">
                            <a href="business/showrooms/details/{{selectedAuto.businessid}}"
                                *ngIf="selectedAuto.business"><img
                                    [src]="pageImages[selectedAuto.businessid + selectedAuto.business[0].businesslogopath]"
                                    alt="{{selectedAuto.business[0].businessname}}" [attr.loading]="'lazy'"></a>
                            <div class="car-single-author-content">
                                <a href="business/showrooms/details/{{selectedAuto.businessid}}"
                                    *ngIf="selectedAuto.business">
                                    <h5>{{selectedAuto.business[0].businessname}}</h5>
                                </a>
                                <h5 *ngIf="!selectedAuto.business">{{selectedAuto.user[0].username}}</h5>
                                <span>{{selectedAuto.business ? selectedAuto.business[0].businessemail :
                                    selectedAuto.user[0].useremail}}</span>
                                <br>
                                <span>+{{selectedAuto.business ? "971" + selectedAuto.business[0].businesscontactnumber
                                    :
                                    selectedAuto.user[0].usermobile}}</span>
                                <br *ngIf="selectedAuto.business && selectedAuto.business[0].businesswhatsappnumber">
                                <a *ngIf="selectedAuto.business && selectedAuto.business[0].businesswhatsappnumber"
                                    target="_blank"
                                    href="https://api.whatsapp.com/send/?phone=971{{selectedAuto.business[0].businesswhatsappnumber}}"><span>+971{{selectedAuto.business[0].businesswhatsappnumber}}</span></a>
                                <!-- <div class="car-single-author-social">
                                    <a href="#"><i class="fab fa-facebook"></i></a>
                                    <a href="#"><i class="fab fa-instagram"></i></a>
                                    <a href="#"><i class="fab fa-twitter"></i></a>
                                    <a href="#"><i class="fab fa-linkedin"></i></a>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="car-single-widget">
                        <h5 class="mb-3">Contact Details</h5>
                        <div class="car-single-form">

                            <div class="form-group">
                                <input type="text" class="form-control" id="txt_name" placeholder="Enter Name">
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" id="txt_email" placeholder="Enter Email">
                            </div>
                            <div class="form-group">
                                <textarea class="form-control" rows="3" id="txt_message"
                                    placeholder="Write Message"></textarea>
                            </div>
                            <div class="form-group">
                                <button type="button" class="theme-btn" (click)="sendMessage()">Send Now<i
                                        class="fas fa-arrow-right-long"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="car-single-related mt-5" *ngIf="similarAuto">
                <h3 class="mb-30">Related Listing</h3>
                <div class="row">
                    <div class="col-md-6 col-lg-4 col-xl-3" *ngFor="let auto of similarAuto">
                        <div class="car-item" (click)="addHits(auto)">
                            <div class="stm-badge-directory heading-font" *ngIf="auto.isPromoted"> Promoted </div>

                            <div class="car-img">
                                <span class="car-status status-1">{{auto.mileage > 0 ?"Used" : "New"}}</span>
                                <img [src]="pageImages[auto.autoid + auto.autophotopath1]"
                                    alt="{{auto.makemodeltrim[0].autodisplayname}}" [attr.loading]="'lazy'">
                                <div class="car-btns">
                                    <a href="#"><i class="far fa-heart"></i></a>
                                    <a href="#"><i class="far fa-arrows-repeat"></i></a>
                                </div>
                            </div>
                            <div class="car-content">
                                <div class="car-top">
                                    <h4><a role="button" (click)="addHits(auto)"><img
                                                [src]="pageImages[auto.makemodeltrim[0].makelogo]"
                                                style="height: 15px !important; padding-right: 5px;"
                                                alt="{{auto.makemodeltrim[0].make}}"
                                                [attr.loading]="'lazy'">{{auto.makemodeltrim[0].autodisplayname}}</a>
                                    </h4>
                                    <!-- <div class="car-rate">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <span>5.0 (58.5k Review)</span>
                                    </div> -->
                                </div>
                                <ul class="car-list">
                                    <li>
                                        <!-- <i class="far fa-steering-wheel"></i> -->
                                        <img src="assets/img/mootori/gearservices.svg" style="height: 14px;"
                                            alt="gear services">
                                        {{ auto.transmission == 1
                                        ? "Automatic"
                                        : "Manual"}}
                                    </li>
                                    <li><i class="far fa-road"></i>{{auto.mileage | number}}km</li>
                                    <li><i class="fa-regular fa-calendar-days"></i>Model: {{auto.year}}</li>
                                    <li><i class="far fa-gas-pump"></i>{{engineType[auto.enginetype - 1]}}</li>
                                </ul>
                                <div class="car-footer">
                                    <span class="car-price">AED {{auto.price | number: '1.0-0'}}</span>
                                    <a role="button" class="theme-btn" (click)="addHits(auto)"><span
                                            class="far fa-eye"></span>Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>