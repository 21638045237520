import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { MootoriAppointmentsService } from 'src/services/mootori-appointments.service';

declare var jQuery: any;
declare var moment: any;


@Component({
  selector: 'app-gms-info',
  templateUrl: './gms-info.component.html',
  styleUrls: ['./gms-info.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GmsInfoComponent implements OnInit {
  locations: string[] = [
    "Abu Dhabi",
    "Al Ain",
    "Dubai",
    "Sharjah",
    "Ajman",
    "Umm Al-Quwain",
    "Fujairah",
    "Ras Alkhaima"
  ];

  addDay: number = 0;

  selectedType: number | null = null;

  selectedDate: Date | null = null;

  selectedDay: number = parseInt(moment().format("D"));

  currentHour: number = parseInt(moment().format("H"));
  availableAfter: number = 3;

  ristrectedEmirats: boolean = false;

  allApointments: any[] = [];

  bookCompleted: boolean = false;

  constructor(private mootoriAppointmentsService: MootoriAppointmentsService, private ngZone: NgZone) {
    // document.querySelector(".preloader")?.setAttribute("style", "display: none;");

    if (this.currentHour > 19) this.addDay = 1;
  }

  async ngOnInit(): Promise<void> {

    try {
      this.allApointments = await this.mootoriAppointmentsService.getAllApointmentsAsync();
    } catch (error) {

    }

    document.querySelector(".preloader")?.setAttribute("style", "display: none;");

    let _self = this;
    setTimeout(() => {
      (function ($) {
        $('.date').datepicker({
          autoclose: true,
          format: 'dd/mm/yyyy',
          startDate: moment().add(_self.addDay, 'days').format('DD/MM/YYYY'),
          endDate: moment().add(3, 'months').format('DD/MM/YYYY'),
          weekStart: 1,
          // daysOfWeekHighlighted: [0, 6],
          // daysOfWeekDisabled: [1, 2, 3, 4, 5],
          clearBtn: true,
        }).on('changeDate', function (e: any) {
          let day = (<Date>e.date).getDate();

          _self.selectedDate = <Date>e.date;

          _self.ngZone.run(() => {
            _self.selectedDay = day;
          })
        });

        $('#AppointmentModal').on('hidden.bs.modal', function (e: any) {
          $('#appointment_form').trigger("reset");

          document.querySelectorAll(".fv-plugins-message-container").forEach((e, i) => {
            e.remove();
          })

          _self.bookCompleted = false;
        })
      })(jQuery);
    }, 200);
  }

  setEmirate(event: any) {
    let emirate = event.target.value;

    this.ristrectedEmirats = emirate != 'Sharjah' && emirate != 'Dubai';

    let _self = this;

    if (this.ristrectedEmirats && this.selectedType == 2) {
      (function ($) {
        $('#ddl_time').val("");

        $('.date').datepicker('destroy');
        $('.date').datepicker({
          autoclose: true,
          format: 'dd/mm/yyyy',
          startDate: moment().add(_self.addDay, 'days').format('DD/MM/YYYY'),
          endDate: moment().add(3, 'months').format('DD/MM/YYYY'),
          weekStart: 1,
          daysOfWeekHighlighted: [5, 0],
          daysOfWeekDisabled: [1, 2, 3, 4, 6],
          clearBtn: true,
        }).on('changeDate', function (e: any) {
          let day = (<Date>e.date).getDate();

          _self.selectedDate = <Date>e.date;

          _self.ngZone.run(() => {
            _self.selectedDay = day;
          })
        });

        if ($('#txt_date').val() != "")
          $('.date').datepicker('clearDates');

        _self.selectedDate = null;
      })(jQuery);
    } else {
      (function ($) {
        $('#ddl_time').val("");

        $('.date').datepicker('destroy');
        $('.date').datepicker({
          autoclose: true,
          format: 'dd/mm/yyyy',
          startDate: moment().add(_self.addDay, 'days').format('DD/MM/YYYY'),
          endDate: moment().add(3, 'months').format('DD/MM/YYYY'),
          weekStart: 1,
          // daysOfWeekHighlighted: [0, 6],
          // daysOfWeekDisabled: [1, 2, 3, 4, 5],
          clearBtn: true,
        }).on('changeDate', function (e: any) {
          let day = (<Date>e.date).getDate();

          _self.selectedDate = <Date>e.date;

          _self.ngZone.run(() => {
            _self.selectedDay = day;
          })
        });

        if ($('#txt_date').val() != "")
          $('.date').datepicker('clearDates');

        _self.selectedDate = null;
      })(jQuery);
    }
  }

  setMeetingType(event: any) {
    var val = event.target.value;

    this.selectedType = parseInt(val);

    let _self = this;

    if (val == "2" && this.ristrectedEmirats) {
      (function ($) {
        $('#ddl_time').val("");

        $('.date').datepicker('destroy');
        $('.date').datepicker({
          autoclose: true,
          format: 'dd/mm/yyyy',
          startDate: moment().add(_self.addDay, 'days').format('DD/MM/YYYY'),
          endDate: moment().add(3, 'months').format('DD/MM/YYYY'),
          weekStart: 1,
          daysOfWeekHighlighted: [5, 0],
          daysOfWeekDisabled: [1, 2, 3, 4, 6],
          clearBtn: true,
        }).on('changeDate', function (e: any) {
          let day = (<Date>e.date).getDate();

          _self.ngZone.run(() => {
            _self.selectedDay = day;
          })
        });

        if ($('#txt_date').val() != "")
          $('.date').datepicker('clearDates');

        _self.selectedDate = null;
      })(jQuery);
    } else {
      (function ($) {
        $('#ddl_time').val("");

        $('.date').datepicker('destroy');
        $('.date').datepicker({
          autoclose: true,
          format: 'dd/mm/yyyy',
          startDate: moment().add(_self.addDay, 'days').format('DD/MM/YYYY'),
          endDate: moment().add(3, 'months').format('DD/MM/YYYY'),
          weekStart: 1,
          // daysOfWeekHighlighted: [0, 6],
          // daysOfWeekDisabled: [1, 2, 3, 4, 5],
          clearBtn: true,
        }).on('changeDate', function (e: any) {
          let day = (<Date>e.date).getDate();

          _self.ngZone.run(() => {
            _self.selectedDay = day;
          })
        });

        if ($('#txt_date').val() != "")
          $('.date').datepicker('clearDates');

        _self.selectedDate = null;
      })(jQuery);
    }

    this.selectedDay = parseInt(moment().format("D"));
  }

  canSelect(time: number) {
    return (this.currentHour + this.availableAfter) < time || this.selectedDay > parseInt(moment().format("D"));
  }

  isBooked(time: number) {
    if (this.allApointments && this.allApointments.length > 0) {
      if (this.selectedDate) {
        let exist = this.allApointments.filter((a: any) => moment(a.meetingdate).format("DD/MM/YYYY") == moment(this.selectedDate).format("DD/MM/YYYY") && a.meetingtime == time && [1, 2].includes(a.status));

        return exist && exist.length > 0;
      }
    }

    return false;
  }

  validateEmail(value: string) {
    let pattern = "^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9-_]+\.[a-zA-Z]+";
    let regex = new RegExp(pattern);
    return (!regex.test(value)) ? false : true;
  }

  validateMobile(value: string) {
    let pattern = /^(05\d{8}|5\d{8,9})$/;
    let regex = new RegExp(pattern);
    return (!regex.test(value)) ? false : true;
  }

  async save() {
    let name = <HTMLInputElement>document.getElementById("txt_name");
    let garage_name = <HTMLInputElement>document.getElementById("txt_garage_name");
    let mobile_number = <HTMLInputElement>document.getElementById("txt_mobile_number");
    let email_address = <HTMLInputElement>document.getElementById("txt_email_address");
    let emirate = <HTMLSelectElement>document.getElementById("ddl_emirate");
    let meeting_type = <HTMLInputElement>document.querySelector("input[name='meeting_type']:checked");
    let date = <HTMLInputElement>document.getElementById("txt_date");
    let time = <HTMLSelectElement>document.getElementById("ddl_time");

    document.querySelectorAll(".fv-plugins-message-container").forEach((e, i) => {
      e.remove();
    })

    let hasError = false;

    if (name.value.trim() == "") {
      const newDiv = document.createElement('div');
      newDiv.classList.add("fv-plugins-message-container");
      newDiv.classList.add("invalid-feedback");
      newDiv.innerHTML = '<div data-field="txt_name">Enter Name</div>';

      (<HTMLElement>name).insertAdjacentElement('afterend', newDiv);

      hasError = true;
    }

    if (garage_name.value.trim() == "") {
      const newDiv = document.createElement('div');
      newDiv.classList.add("fv-plugins-message-container");
      newDiv.classList.add("invalid-feedback");
      newDiv.innerHTML = '<div data-field="txt_name">Enter Mobile Number</div>';

      (<HTMLElement>garage_name).insertAdjacentElement('afterend', newDiv);

      hasError = true;
    }

    if (mobile_number.value.trim() == "") {
      const newDiv = document.createElement('div');
      newDiv.classList.add("fv-plugins-message-container");
      newDiv.classList.add("invalid-feedback");
      newDiv.innerHTML = '<div data-field="txt_name">Enter Mobile Number</div>';

      (<HTMLElement>mobile_number).insertAdjacentElement('afterend', newDiv);

      hasError = true;
    } else if (!this.validateMobile(mobile_number.value.trim())) {
      const newDiv = document.createElement('div');
      newDiv.classList.add("fv-plugins-message-container");
      newDiv.classList.add("invalid-feedback");
      newDiv.innerHTML = '<div data-field="txt_name">Enter Correct Mobile Number</div>';

      (<HTMLElement>mobile_number).insertAdjacentElement('afterend', newDiv);

      hasError = true;
    }

    if (email_address.value.trim() == "") {
      const newDiv = document.createElement('div');
      newDiv.classList.add("fv-plugins-message-container");
      newDiv.classList.add("invalid-feedback");
      newDiv.innerHTML = '<div data-field="txt_name">Enter Email Addressr</div>';

      (<HTMLElement>email_address).insertAdjacentElement('afterend', newDiv);

      hasError = true;
    } else if (!this.validateEmail(email_address.value.trim())) {
      const newDiv = document.createElement('div');
      newDiv.classList.add("fv-plugins-message-container");
      newDiv.classList.add("invalid-feedback");
      newDiv.innerHTML = '<div data-field="txt_name">Enter Correct Email Addressr</div>';

      (<HTMLElement>email_address).insertAdjacentElement('afterend', newDiv);

      hasError = true;
    }

    if (emirate.value.trim() == "") {
      const newDiv = document.createElement('div');
      newDiv.classList.add("fv-plugins-message-container");
      newDiv.classList.add("invalid-feedback");
      newDiv.innerHTML = '<div data-field="txt_name">Select Emirate</div>';

      (<HTMLElement>emirate).insertAdjacentElement('afterend', newDiv);

      hasError = true;
    }

    if (!meeting_type) {
      const newDiv = document.createElement('div');
      newDiv.classList.add("fv-plugins-message-container");
      newDiv.classList.add("invalid-feedback");
      newDiv.innerHTML = '<div data-field="txt_name">Select Meeting Type</div>';

      (<HTMLElement>document.querySelector(".meeting-type")).insertAdjacentElement('afterend', newDiv);

      hasError = true;
    }

    if (date.value.trim() == "") {
      const newDiv = document.createElement('div');
      newDiv.classList.add("fv-plugins-message-container");
      newDiv.classList.add("invalid-feedback");
      newDiv.innerHTML = '<div data-field="txt_name">Select Date</div>';

      (<HTMLElement>document.querySelector(".date")).insertAdjacentElement('afterend', newDiv);

      hasError = true;
    }

    if (time.value.trim() == "") {
      const newDiv = document.createElement('div');
      newDiv.classList.add("fv-plugins-message-container");
      newDiv.classList.add("invalid-feedback");
      newDiv.innerHTML = '<div data-field="txt_name">Select Time</div>';

      (<HTMLElement>time).insertAdjacentElement('afterend', newDiv);

      hasError = true;
    }

    if (hasError) return;

    let mobile = "";

    if (mobile_number.value.length == 10 && mobile_number.value[0] == "0")
      mobile = mobile_number.value.substring(1);
    else
      mobile = mobile_number.value;

    let data: any = {
      "username": name.value,
      "garagename": garage_name.value,
      "usermobilenumber": `971${mobile}`,
      "useremailaddress": email_address.value,
      "emirate": emirate.value,
      "meetingtype": meeting_type.value,
      "meetingdate": moment(this.selectedDate!.toString()).format("DD-MM-YYYY"),
      "meetingtime": time.value,
    }

    try {
      await this.mootoriAppointmentsService.addAppointmentAsync(data);
      this.bookCompleted = true;
    } catch (error) {

    }

  }
}
