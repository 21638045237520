import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutoService {

  private APIURL: string = environment.APIURL;
  private APIKEY: string = environment.APIKEY;

  constructor(private http: HttpClient) { }

  getAllAutos(limit: number = 0, lat: number = -1, lng: number = -1, filterData: any[] | null = null): Observable<any> {
    let theUrl =
      this.APIURL + "automaster/read_promoted_and_refreshes.php?limit=" + limit + "&onlyactive=1&onlyapproved=1&userlat=" + lat + "&userlng=" + lng;

    if (filterData != null && filterData.length > 0) {
      theUrl += "&filter=" + JSON.stringify(filterData);
    }

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    });
  }

  async getAllAutosAsync(limit: number = 0, lat: number = -1, lng: number = -1, filterData: any[] | null = null) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.getAllAutos(limit, lat, lng, filterData)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }

  getBusinessAuto(businessid: string): Observable<any> {
    const theUrl =
      this.APIURL + "automaster/read_by_businessid.php?businessid=" + businessid + "&onlyactive=1&onlyapproved=1";

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    });
  }

  async getBusinessAutoAsync(businessid: string) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.getBusinessAuto(businessid)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Not Found
          if (msg.status == 404)
            resolve(null);
          else {
            // Error
            reject(msg);
          }
        });
    });
    return await promise;
  }

  getAuto(
    { autoid, lat = -1, lng = -1, onlyactive = 1, onlyapproved = 1 }: { autoid: string, lat?: number, lng?: number, onlyactive?: number, onlyapproved?: number }): Observable<any> {
    const theUrl =
      this.APIURL + "automaster/read_one.php?autoid=" + autoid + "&onlyactive=" + onlyactive + "&onlyapproved=" + onlyapproved + "&userlat=" + lat + "&userlng=" + lng;

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    });
  }

  async getAutoAsync({ autoid, lat = -1, lng = -1, onlyactive = 1, onlyapproved = 1 }: { autoid: string, lat?: number, lng?: number, onlyactive?: number, onlyapproved?: number }) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.getAuto({ autoid, lat: lat, lng: lng, onlyactive: onlyactive, onlyapproved: onlyapproved })).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }

  getUserAuto(userid: string, lat: number = -1, lng: number = -1): Observable<any> {
    const theUrl =
      this.APIURL + "automaster/read_by_userid.php?userid=" + userid + "&limit=0&onlyactive=0e&onlyapproved=0&userlat=" + lat + "&userlng=" + lng;

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    });
  }

  async getUserAutoAsync(autoid: string, lat: number = -1, lng: number = -1) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.getUserAuto(autoid, lat, lng)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }

  getSimilarAuto(autoid: number,
    make: string,
    vehicletype: string,
    lat: number = -1,
    lng: number = -1,
    count: number = 10,
    onlyActive: boolean = true,
    onlyApproved: boolean = true,
    promotedOrder: boolean = true
  ): Observable<any> {
    const theUrl =
      this.APIURL + "automaster/read_similar_auto.php?autoid=" + autoid + "&make=" + make + "&vehicletype=" + vehicletype + "&limit=" + count + "&onlyactive=1&onlyapproved=1&userlat=" + lat + "&userlng=" + lng;

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    });
  }

  async getSimilarAutoAsync(autoid: number,
    make: string,
    vehicletype: string,
    lat: number = -1,
    lng: number = -1,
    count: number = 10,
    onlyActive: boolean = true,
    onlyApproved: boolean = true,
    promotedOrder: boolean = true) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.getSimilarAuto(autoid, make, vehicletype, lat, lng, count, onlyActive, onlyApproved, promotedOrder)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }

  identifyDuplicates(data: Object[], key: string, isunique: boolean =
    true) {
    const unique: Object[] = [];
    const duplicates = data.filter((x: any) => {
      if (unique.length > 0 && (unique?.find((u: any) => u[key] == x[key]) != null)) { return true; }
      unique.push(x);
      return false;
    });
    return isunique ? unique : duplicates;
  }

  getAllVehicleBodyType(): Observable<any> {
    const theUrl =
      this.APIURL + "vehiclebodytype/read.php";

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    });
  }

  async getAllVehicleBodyTypeAsync() {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.getAllVehicleBodyType()).then(res => {
        let result = res.body;
        // console.log(result);

        // let output = result.map((vt: any) => {
        //   return {
        //     "vehicletypeen": vt["vehicletypeen"],
        //     "vehicletypear": vt["vehicletypear"],
        //     "vehicletypeimage": vt["vehicletypeimage"],
        //   };
        // });

        //output = this.identifyDuplicates(output, "vehicletypeen");

        resolve(result);
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }

  getMakeByVehicleType(vehicletype: string): Observable<any> {
    const theUrl =
      this.APIURL + "makemodeltrim/read_make_by_vehicle_type.php?vehicletype=" + vehicletype;

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    });
  }

  async getMakeByVehicleTypeAsync(vehicletype: string) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.getMakeByVehicleType(vehicletype)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }

  getModelByVehicleType(vehicletype: string, make: string): Observable<any> {
    const theUrl =
      this.APIURL + "makemodeltrim/read_model_by_vehicle_type.php?vehicletype=" + vehicletype + "&make=" + make;

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    });
  }

  async getModelByVehicleTypeAsync(vehicletype: string, make: string) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.getModelByVehicleType(vehicletype, make)).then(res => {
        // let result = res.body;
        // let output = result.map((vt: any) => {
        //   return {
        //     "model": vt["model"],
        //     "makelogo": vt["makelogo"],
        //   };
        // });

        // output = this.identifyDuplicates(output, "model");

        // resolve(output);

        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }

  getAllFeatures(): Observable<any> {
    const theUrl =
      this.APIURL + "autofeatures/read.php";

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.get<any>(theUrl, {
      headers: headers,
      observe: 'response'
    });
  }

  async getAllFeaturesAsync() {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.getAllFeatures()).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }

  addAuto(data: any): Observable<any> {
    const theUrl =
      this.APIURL + "automaster/create.php";

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.post<any>(theUrl, data, {
      headers: headers,
      observe: 'response'
    });
  }

  async addAutoAsync(data: any) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.addAuto(data)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }

  updateAuto(data: any): Observable<any> {
    const theUrl =
      this.APIURL + "automaster/update.php"

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.post<any>(theUrl, data, {
      headers: headers,
      observe: 'response'
    });
  }

  async updateAutoAsync(data: any) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.updateAuto(data)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }

  addHits(data: any): Observable<any> {
    const theUrl =
      this.APIURL + "businessautohits/create.php";

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.post<any>(theUrl, data, {
      headers: headers,
      observe: 'response'
    });
  }

  async addHitsAsync(data: any) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.addHits(data)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }
}
