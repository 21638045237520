import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private APIURL: string = environment.APIURL;
  private APIKEY: string = environment.APIKEY;

  constructor(private http: HttpClient) { }

  // Returns an observable
  upload(files: any[], id: any): Observable<any> {
    const theUrl = this.APIURL + "file/upload.php?del=true";

    let headers = {
      // "Content-Type": "file",
      // "Connection": "keep-alive",
      // "Accept-Encoding": "gzip, deflate, br",
      // "Accept": "*/*",
      'X-Api-Key': this.APIKEY,
    };

    // Create form data
    const formData = new FormData();

    // Store form name as "file" with file data
    for (let i = 0; i < files.length; i++) {
      let uploadFiles = files[i].files;
      let fileName = files[i].fileName;

      for (let j = 0; j < uploadFiles.length; j++) {
        let file: File = uploadFiles[j];

        var extIndex = file.name.lastIndexOf(".") + 1;

        var ext = file.name
          .substring(extIndex)
          .replaceAll(RegExp(/[^A-Za-z]/g), '');

        var name =
          uploadFiles.length > 1 ? (id + fileName + "_" + (j + 1) + "." + ext) : (id + fileName + "." + ext);

        formData.append("formFiles[]", file, name);
      }
    }

    // let data = formData.getAll("formFiles[]");

    // console.log(data);

    // Make http post request over api
    // with formData as req
    return this.http.post(theUrl, formData, {
      headers: headers,
    });
  }

  /**
   * Generates a presigned URL for accessing an S3 object.
   * This method assumes you call your backend API to get the presigned URL.
   * @param key The S3 object key (file path).
   * @returns An Observable containing the presigned URL.
   */
  async getPresignedUrl(key: string): Promise<string> {
    const theUrl = this.APIURL + "file/aws_read_file.php?key=" + key;

    let headers = {
      // "Content-Type": "file",
      // "Connection": "keep-alive",
      // "Accept-Encoding": "gzip, deflate, br",
      // "Accept": "*/*",
      'X-Api-Key': this.APIKEY,
    };

    try {
      const response = await lastValueFrom(this.http.get<{ url: string }>(theUrl, {
        headers: headers
      }));
      return response.url;
    } catch (error) {
      console.error('Error fetching presigned URL:', error);
      throw error;
    }
  }

  async getBulkPresignedUrls(imageKeys: string[]): Promise<{ [key: string]: string }> {
    const theUrl = this.APIURL + "file/aws_read_bulk_files.php";

    let headers = {
      // "Content-Type": "file",
      // "Connection": "keep-alive",
      // "Accept-Encoding": "gzip, deflate, br",
      // "Accept": "*/*",
      'X-Api-Key': this.APIKEY,
    };

    try {
      const response = await lastValueFrom(this.http.post<{ [key: string]: string }>(theUrl, imageKeys, {
        headers: headers
      }));
      return response;
    } catch (error) {
      console.error('Error fetching presigned URLs:', error);
      throw error;
    }
  }

  // Returns an observable
  awsUpload(files: any[], id: any, subDirectory: string = ""): Observable<any> {
    const theUrl = this.APIURL + ((subDirectory != "")
      ? "file/aws_upload.php?del=true&subDirectory=" + subDirectory
      : "file/aws_upload.php?del=true");

    let headers = {
      // "Content-Type": "file",
      // "Connection": "keep-alive",
      // "Accept-Encoding": "gzip, deflate, br",
      // "Accept": "*/*",
      'X-Api-Key': this.APIKEY,
    };

    // Create form data
    const formData = new FormData();

    // Store form name as "file" with file data
    for (let i = 0; i < files.length; i++) {
      let uploadFiles = files[i].files;
      let fileName = files[i].fileName;

      for (let j = 0; j < uploadFiles.length; j++) {
        let file: File = uploadFiles[j];

        var extIndex = file.name.lastIndexOf(".") + 1;

        var ext = file.name
          .substring(extIndex)
          .replaceAll(RegExp(/[^A-Za-z]/g), '');

        var name =
          uploadFiles.length > 1 ? (id + fileName + "_" + (j + 1) + "." + ext) : (id + fileName + "." + ext);

        formData.append("formFiles[]", file, name);
      }
    }

    // let data = formData.getAll("formFiles[]");

    // console.log(data);

    // Make http post request over api
    // with formData as req
    return this.http.post(theUrl, formData, {
      headers: headers,
    });
  }

  awsMoveDraftFiles(data: any): Observable<any> {
    const theUrl =
      this.APIURL + "file/aws_move_draft_files.php";

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Key': this.APIKEY,
    };
    return this.http.post<any>(theUrl, data, {
      headers: headers,
      observe: 'response'
    });
  }

  async awsMoveDraftFilesAsync(data: any) {
    let promise = new Promise<any>((resolve, reject) => {
      lastValueFrom(this.awsMoveDraftFiles(data)).then(res => {
        resolve(res != null ? res.body : null);;
      },
        msg => {
          // Error
          reject(msg);
        });
    });
    return await promise;
  }
}
