// import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  usesocial: boolean = false;

  logingCompleted: boolean = false;
  // currentOTP: string = "";

  currentUser: any = null;

  sendTo: string | null = null;

  private _loginType: string | null = null;

  isTest: boolean = false;

  constructor(private authenticationService: AuthenticationService/*, private socialAuthService: SocialAuthService*/) {
    document.querySelector(".preloader")?.setAttribute("style", "display: none;");
  }

  ngOnInit(): void {
    localStorage.removeItem('user');

    // this.socialAuthService.authState.subscribe((user) => {
    //   this.currentUser = user;
    //   // this.isLoggedin = user != null;
    // });
  }

  setLoginType(type: string) {
    this._loginType = type;
  }

  async login(event: any) {
    this.sendTo = null;

    event.stopPropagation()
    event.preventDefault();

    var loginBy = this._loginType === "email" ? (<HTMLInputElement>document.querySelector("[name='email']")).value : '971' + parseInt((<HTMLInputElement>document.querySelector("[name='mobile']")).value).toString();

    document.querySelector(".preloader")?.removeAttribute("style");

    var isBusnessUser: boolean = false;

    try {
      var user = await this.authenticationService.getUserAsync(loginBy);
      isBusnessUser = (await this.authenticationService.isBusnessUserAsync(user.userid)).count > 0;
    } catch (error) {

    }

    this.currentUser = user;

    this.sendTo = loginBy;

    this.logingCompleted = true;

    document.querySelector(".preloader")?.setAttribute("style", "display: none;");

    if (!this.isTest) {

      //this.currentOTP = this.authenticationService.generateOTP();

      if (this._loginType === "email") {
        // this.authenticationService.sendOTP(user.useremail, this.currentOTP);

        await this.authenticationService.sendPHPOTPAsync(user.useremail, "email");

      } else {
        // let mes = `Your OTP is ${this.currentOTP}, it valid only for 5 minutes. Use this Passcode to complete your transaction.`

        // await this.authenticationService.sendSMSAsync(this.currentUser.usermobile, mes);

        // await this.authenticationService.smsOTPAsync(loginBy);

        await this.authenticationService.sendPHPOTPAsync(user.usermobile, "sms");
      }
    }

    return false;
  }

  async otpVerify() {
    if (!this.isTest) {
      var otp1 = (<HTMLInputElement>document.querySelector("[name='code_1']"));
      var otp2 = (<HTMLInputElement>document.querySelector("[name='code_2']"));
      var otp3 = (<HTMLInputElement>document.querySelector("[name='code_3']"));
      var otp4 = (<HTMLInputElement>document.querySelector("[name='code_4']"));
      var otp5 = (<HTMLInputElement>document.querySelector("[name='code_5']"));
      var otp6 = (<HTMLInputElement>document.querySelector("[name='code_6']"));

      var otp = otp1.value + otp2.value + otp3.value + otp4.value + otp5.value + otp6.value;

      document.querySelector(".preloader")?.removeAttribute("style");

      if (this.currentUser != null) {
        if (this._loginType === "email") {
          var result: any = await this.authenticationService.verifyPHPOTPAsync(this.currentUser.useremail, otp);

          if (result.status == "success") {
            localStorage.setItem('loginby', "PHPAPI");

            localStorage.setItem('user', JSON.stringify(this.currentUser));

            window.location.href = "home";
          } else {
            //this.otpErrorMasssege = "Invalid or expired OTP";
          }
        } else {
          var result: any = await this.authenticationService.verifyPHPOTPAsync(this.currentUser.usermobile, otp);

          if (result.status == "success") {
            localStorage.setItem('loginby', "PHPAPI");

            localStorage.setItem('user', JSON.stringify(this.currentUser));

            window.location.href = "home";
          } else {
            //this.otpErrorMasssege = "Invalid or expired OTP";
          }
        }
      }

      //   if (this._loginType === "email") {
      //     if (otp === this.currentOTP && this.currentUser !== null) {
      //       localStorage.setItem('user', JSON.stringify(this.currentUser));

      //       window.location.href = "home";
      //     }
      //   } else {
      //     if (otp === this.currentOTP && this.currentUser !== null) {
      //       localStorage.setItem('user', JSON.stringify(this.currentUser));

      //       window.location.href = "home";
      //     }

      //     // var verified: any = await this.authenticationService.verifyOTPAsync(this.currentUser.usermobile, otp);

      //     // if (verified["status"] == "Verified") {
      //     //   localStorage.setItem('user', JSON.stringify(this.currentUser));

      //     //   window.location.href = "home";
      //     // }
      //   }
      // } else {
      //   localStorage.setItem('user', JSON.stringify(this.currentUser));

      //   window.location.href = "home";
    }

    document.querySelector(".preloader")?.setAttribute("style", "display: none;");

    return false;
  }

  async resendOTP() {
    // this.currentOTP = this.authenticationService.generateOTP();

    if (this._loginType === "email") {
      // this.authenticationService.sendOTP(this.currentUser.useremail, this.currentOTP);
      await this.authenticationService.sendPHPOTPAsync(this.currentUser.useremail, "email");
    } else {
      // let mes = `Your OTP is ${this.currentOTP}, it valid only for 5 minutes. Use this Passcode to complete your transaction.`

      // await this.authenticationService.sendSMSAsync(this.currentUser.usermobile, mes);

      // await this.authenticationService.smsOTPAsync(this.currentUser.usermobile);

      await this.authenticationService.sendPHPOTPAsync(this.currentUser.usermobile, "sms");
    }

    return false;
  }

  loginWithFacebook(): void {
    // document.querySelector(".only-lock")?.removeAttribute("style");

    // this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(async u => {
    //   var user = await this.authenticationService.getUserAsync(u.email);

    //   if (user) {
    //     this._loginType = "email"

    //     this.currentUser = user;

    //     localStorage.setItem('user', JSON.stringify(this.currentUser));

    //     window.location.href = "home";
    //   }

    //   document.querySelector(".only-lock")?.setAttribute("style", "display: none;");
    // });
  }

  loginWithGoogle(): void {
    document.querySelector(".only-lock")?.removeAttribute("style");

    document.getElementById("btn_google")?.click();

    // this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(accessToken => {
    //   debugger;

    //   this.socialAuthService.authState.subscribe((user) => {
    //     let u = user;
    //     debugger;
    //   });
    //   // var user = await this.authenticationService.getUserAsync(u.email);

    //   // if (user) {
    //   //   this._loginType = "email"

    //   //   this.currentUser = user;

    //   //   localStorage.setItem('user', JSON.stringify(this.currentUser));

    //   //   window.location.href = "home";
    //   // }

    //   document.querySelector(".only-lock")?.setAttribute("style", "display: none;");
    // });
  }

  signOut(): void {
    // this.socialAuthService.signOut();
  }
}
