import { Component, ElementRef, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Select2OptionData } from 'ng-select2';
import { AgmMap, MapsAPILoader } from '@agm/core';

import { NgxImageCompressService } from 'ngx-image-compress';

import { AutoService } from 'src/services/auto.service';
import { DynamicScriptLoaderService } from 'src/services/dynamic-script-loader.service';
import { environment } from 'src/environments/environment';
import { FileUploadService } from 'src/services/file-upload.service';
import { catchError, throwError } from 'rxjs';

declare var jQuery: any;

declare var Inputmask: any;

interface Vehicle {
  chassisnumber?: string | null;
  userid?: number | null;
  businessid?: number | null;
  makemodeltrimid?: number | null;
  year?: number | null;
  autodescription?: string | null;
  autocolour?: number | null;
  mobilenumber?: string | null;
  email?: string | null;
  gccspecs?: number | null;
  enginetype?: number | null,
  vehicletype?: string | null;
  bodystyle?: string | null;
  hoursepower?: number | null;
  numberofcylinders?: number | null;
  citycode?: string | null;
  autolat?: number | null;
  autolng?: number | null;
  autoaddress?: string | null;
  country?: string | null;
  city?: string | null;
  bodycondition?: number | null;
  mechanicalcondition?: number | null;
  doors?: number | null;
  warranty?: number | null;
  steeringside?: number | null;
  transmission?: number | null;
  mileage?: number | null;
  price?: number | null;
  autophotopath1?: string | null;
  autophotopath2?: string | null;
  autophotopath3?: string | null;
  autophotopath4?: string | null;
  autophotopath5?: string | null;
  autophotopath6?: string | null;
  autophotopath7?: string | null;
  autophotopath8?: string | null;
  autophotopath9?: string | null;
  autophotopath10?: string | null;
  inspectionreportpath?: string | null;
  autofeatures?: any[] | null;
  addedfrom: number | null;
}

@Component({
  selector: 'app-add-auto',
  templateUrl: './add-auto.component.html',
  styleUrls: ['./add-auto.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AddAutoComponent implements OnInit {
  USERSFILESURL: string = environment.USERSFILESURL;
  FILESURL: string = environment.FILESURL;

  currentUser: any = null;

  showRooms: any[] | null = null;
  hasShowrooms: boolean = false;

  defaultBounds: any;
  componentRestrictions: any;
  latitude: number = 25.192118;
  longitude: number = 55.279468;
  zoom = 16;
  currentLocation: any;
  country: any;
  city: any;

  @ViewChild(AgmMap, { static: true }) public agmMap!: AgmMap;
  @ViewChild('search') public searchElementRef!: ElementRef;

  errorsList: string[] = [];

  vehicle: Vehicle = {
    chassisnumber: null,
    userid: null,
    businessid: null,
    makemodeltrimid: null,
    year: null,
    autodescription: null,
    autocolour: null,
    mobilenumber: null,
    email: null,
    gccspecs: null,
    enginetype: null,
    vehicletype: null,
    bodystyle: null,
    hoursepower: null,
    numberofcylinders: null,
    citycode: '00971',
    autolat: null,
    autolng: null,
    autoaddress: null,
    country: null,
    city: null,
    bodycondition: null,
    mechanicalcondition: null,
    doors: null,
    warranty: null,
    steeringside: null,
    transmission: null,
    mileage: null,
    price: null,
    autophotopath1: null,
    autophotopath2: null,
    autophotopath3: null,
    autophotopath4: null,
    autophotopath5: null,
    autophotopath6: null,
    autophotopath7: null,
    autophotopath8: null,
    autophotopath9: null,
    autophotopath10: null,
    inspectionreportpath: null,
    autofeatures: null,
    addedfrom: 1
  };

  allVehicleTypes: any[] | null = [];
  vehicleTypes: any[] | null = [];
  selectedVehicletype: string = "";

  vehicleBodyTypes: any[] = [];
  selectedVehicleBodytype: string = "";

  years: Array<Select2OptionData> = [];
  formControl = new FormControl();
  selectedYear: string | null = "";

  makesList: Array<Select2OptionData> = [];
  makeSelectedItem: any;

  modelsTrimsList: any[] | null = [];

  modelsList: Array<Select2OptionData> = [];
  modelSelectedItem: any;

  trimsList: Array<Select2OptionData> = [];
  trimSelectedItem: any;

  carSpe = [
    "American",
    "GCC/UAE Purchased",
    "Other Europian Country",
    "i Dont know"
  ];

  engineType = ["Petrol", "Diesel", "Hybrid", "Electric"];

  bodycondition = [
    "Perfect inside and out",
    "No accidents, very few faults",
    "A bit of wear and tear, all repaired",
    "Normal wear and teal, a few issues",
    "Lots of wear and tear with the body",
  ];

  mechanicalcondition = [
    "Perfect",
    "Minor Faults, all fixed",
    "Major Faults, all fixed",
    "Major faults fixed, few remains",
    "Ongoing minor or major faults",
  ];

  warranty = [
    "Yes",
    "No",
    "Does not apply",
  ];

  steeringside = ["Left", "Right", "Does not apply"];

  transmission = ["Automatic", "Manual"];

  features: any[] | null = [];
  selectedFeatures: any[] | null = [];


  coverFile: any;
  otherFiles: any[] = [];
  files: any[] = [];

  colorList = [
    "Black",
    "Blue",
    "Brown",
    "#ffc107",
    "Grey",
    "Orange",
    "Green",
    "Purple",
    "Red",
    "Teal",
    "White",
    "Yellow",
  ];

  isValid: boolean = false;

  constructor(private autoService: AutoService, private fileUploadService: FileUploadService, private dynamicScriptLoader: DynamicScriptLoaderService, private sanitizer: DomSanitizer,
    private apiloader: MapsAPILoader,
    private ngZone: NgZone, private imageCompress: NgxImageCompressService) {
    this.currentUser = JSON.parse(localStorage.getItem('user')!);

    document.getElementById("map_script")?.remove();
  }

  async ngOnInit(): Promise<void> {
    if (!this.currentUser)
      window.location.href = "home";
    else {
      this.dynamicScriptLoader.load("inputmask");

      if (this.currentUser.isbusnessuser && this.currentUser.businesses) {
        this.showRooms = (<any[]>this.currentUser.businesses).filter(b => b.businessactivitytype == 3 && b.businessstatus == 1);

        this.hasShowrooms = this.showRooms && this.showRooms.length > 0;
      }

      this.vehicle.userid = this.currentUser.userid;

      // http://bboxfinder.com/
      // https://gist.github.com/chriseugenerodriguez/659a84b989b3f35cb034a9f9ee1a914f
      // {'AE': {'United Arab Emirates': [51.5795186705, 22.4969475367, 56.3968473651, 26.055464179]}},
      // 22.452164,51.333232,26.441565,57.145000
      /**
        Box 22.479370,51.318212,26.477757,57.109079
        Map 19.590844,48.197021,28.023500,64.346924
        Center 23.875792,56.271973

        Box 51.318212,22.479370,57.109079,26.477757
        Map 48.197021,19.590844,64.346924,28.023500
        Center 56.271973,23.875792
      */

      this.defaultBounds = {
        north: 51.333232,
        south: 22.452164,
        east: 57.145000,
        west: 26.441565,
      };

      this.componentRestrictions = {
        latLngBounds: {
          north: 51.333232,
          south: 22.452164,
          east: 57.145000,
          west: 26.441565,
        },
        strictBounds: true,
      }

      let emailCtrl = <HTMLInputElement>document.getElementById("txt_email");
      let mobileCtrl = <HTMLInputElement>document.getElementById("txt_mobile");

      emailCtrl.value = this.currentUser.useremail;
      mobileCtrl.value = this.currentUser.usermobile.substring(3);
    }

    for (let i = 1896; i <= (new Date()).getFullYear() + 1; i++) {
      this.years.push({ id: i.toString(), text: i.toString() });
    }

    this.allVehicleTypes = await this.autoService.getAllVehicleBodyTypeAsync();

    this.vehicleTypes = this.autoService.identifyDuplicates(this.allVehicleTypes!, "vehicletypeen");

    this.features = await this.autoService.getAllFeaturesAsync();

    setTimeout(() => {
      (function ($) {
        // $(document).ready(function () {
        //   $('.featuresimg').each(function (i: any, el: any) {
        //     debugger
        //     var img = $(el);
        //     var image_uri = img.attr('src');

        //     $.get(image_uri, function (data: any) {
        //       var svg = $(data).find('svg');
        //       svg.removeAttr('xmlns:a');
        //       img.replaceWith(svg);
        //     }, 'xml');

        //   });
        // });

        Inputmask("decimal", {
          "rightAlign": false,
          "groupSeparator": ","
        }).mask("#txt_price");

        Inputmask("decimal", {
          "rightAlign": false,
          "groupSeparator": ","
        }).mask("#txt_mailage");
      })(jQuery);

      // let _self = this;

      // document.querySelectorAll('.vehicle-type').forEach((e) => {
      //   e.addEventListener('click', this.selectVehicleBodyType.bind(e, e, _self))
      // })



      document.querySelector(".preloader")?.setAttribute("style", "display: none;");
    }, 50);

    try {
      this.get()
      this.zoom = 16;
      this.agmMap!.triggerResize(true);
    } catch (error) {

    }
  }

  async selectVehicleType(event: MouseEvent) {
    let element = event.target as HTMLElement;

    let div = element;

    let img: HTMLImageElement;
    let span: HTMLSpanElement;

    if (element.tagName.toLowerCase() == "img" || element.tagName.toLowerCase() == "span") {
      div = element.parentNode as HTMLElement;
    }

    let type = div.getAttribute("vtype");

    let vType = this.vehicleTypes!.find((v: any) => v.vehicletypeen == type);
    if (vType) {
      this.selectedVehicletype = vType.vehicletypeen;

      await this.getMakes();

      if (this.selectedVehicletype == "Car" || this.selectedVehicletype == "Heavy Vehicle")
        this.vehicleBodyTypes = this.allVehicleTypes!.filter((t: any) => t.vehicletypeen == this.selectedVehicletype);
      else
        this.vehicleBodyTypes = [];
    }

    img = div.firstChild as HTMLImageElement;
    span = div.children[1] as HTMLSpanElement;

    document.querySelectorAll('.vehicle-type').forEach((d) => {
      d.classList.remove("border-danger");

      let i = d.firstChild as HTMLImageElement;
      let s = d.children[1] as HTMLSpanElement;

      i.src = i.src.replace("-red.svg", ".svg");
      s.classList.remove("text-danger");
    });

    div.classList.add("border-danger");
    img!.src = img!.src.replace(".svg", "-red.svg");
    span!.classList.add("text-danger");

    (function ($) {
      $(document).on('select2:open', () => {
        (<HTMLInputElement>document.querySelector('.select2-search__field')).focus();
      });
    })(jQuery);
  }

  selectVehicleBodyType(event: MouseEvent) {
    let element = event.target as HTMLElement;

    let div = element;

    let img: HTMLImageElement;
    let span: HTMLSpanElement;

    if (element.tagName.toLowerCase() == "img" || element.tagName.toLowerCase() == "span") {
      div = element.parentNode as HTMLElement;
    }

    let type = div.getAttribute("vtype");

    this.selectedVehicleBodytype = type!;

    img = div.firstChild as HTMLImageElement;
    span = div.children[1] as HTMLSpanElement;

    document.querySelectorAll('.vehicle-body-type').forEach((d) => {
      d.classList.remove("border-danger");

      let i = d.firstChild as HTMLImageElement;
      let s = d.children[1] as HTMLSpanElement;

      i.src = i.src.replace("-red.svg", ".svg");
      s.classList.remove("text-danger");
    });

    div.classList.add("border-danger");
    img!.src = img!.src.replace(".svg", "-red.svg");
    span!.classList.add("text-danger");
  }


  selectVehicleFeature(event: MouseEvent) {
    let element = event.target as HTMLElement;

    let div = element;

    let img: HTMLImageElement;
    let span: HTMLSpanElement;

    if (element.tagName.toLowerCase() == "img" || element.tagName.toLowerCase() == "span") {
      div = element.parentNode as HTMLElement;
    }

    let feature = div.getAttribute("feature");

    img = div.firstChild as HTMLImageElement;
    span = div.children[1] as HTMLSpanElement;

    let isExist = this.selectedFeatures!.find((f) => f.featureid == feature);

    if (isExist) {
      div.classList.remove("border-danger");
      img.src = img.src.replace("red.svg", "gray.svg");
      span.classList.remove("text-danger");

      this.selectedFeatures!.forEach((value, index) => {
        if (value.featureid == feature) this.selectedFeatures!.splice(index, 1);
      });

    } else {
      this.selectedFeatures!.push({ featureid: parseInt(feature!) });

      div.classList.add("border-danger");
      img!.src = img!.src.replace("gray.svg", "red.svg");
      span!.classList.add("text-danger");
    }
  }

  async getMakes() {
    try {
      let makes = await this.autoService.getMakeByVehicleTypeAsync(this.selectedVehicletype) as any[];

      this.makesList = makes.map((m: any) => {
        return { id: m.make, text: m.make };
      });
    } catch (error) {
      this.makesList = [];
    }
  }

  async makeChange(make: any) {
    if (make != this.makeSelectedItem) {
      this.makeSelectedItem = make;

      this.modelsTrimsList = await this.autoService.getModelByVehicleTypeAsync(this.selectedVehicletype, this.makeSelectedItem);

      let models = this.autoService.identifyDuplicates(this.modelsTrimsList!, "model");

      this.modelsList = models.map((m: any) => {
        return { id: m.makemodeltrimid, text: m.model };
      });
    }
  }

  modelChanged(model: any) {
    if (model) {
      let modelItem = this.modelsList.find((m) => m.id == model);
      if (!this.modelSelectedItem || (this.modelSelectedItem && this.modelSelectedItem.id != modelItem!.id)) {
        this.modelSelectedItem = modelItem;

        this.vehicle.makemodeltrimid = this.modelSelectedItem.id;

        let desCtrl = <HTMLTextAreaElement>document.getElementById("txt_desctiption");

        let selected = this.modelsTrimsList?.find(mt => mt.makemodeltrimid == this.modelSelectedItem.id);

        desCtrl.value = selected.autodisplayname;

        let list = this.modelsTrimsList!.filter((m: any) => {
          return m.model == this.modelSelectedItem.text;
        });

        let trims = this.autoService.identifyDuplicates(list, "trim");

        this.trimsList = trims.map((m: any) => {
          return { id: m.makemodeltrimid, text: m.trim };
        });
      }
    }
  }

  trimChanged(trim: any) {
    if (trim) {
      let trimItem = this.trimsList.find((m) => m.id == trim);
      if (!this.trimSelectedItem || (this.trimSelectedItem && this.trimSelectedItem.id != trimItem!.id)) {
        this.trimSelectedItem = trimItem;

        this.vehicle.makemodeltrimid = this.trimSelectedItem.id;

        let desCtrl = <HTMLTextAreaElement>document.getElementById("txt_desctiption");

        let selected = this.modelsTrimsList?.find(mt => mt.makemodeltrimid == this.trimSelectedItem.id);

        desCtrl.value = selected.autodisplayname;
      }
    }
  }

  validate(formname: string) {
    this.errorsList = [];
    switch (formname) {
      case "vehicleType":
        if (this.selectedVehicletype == "")
          this.errorsList.push("Select Vehicle Type");
        else
          this.vehicle.vehicletype = this.selectedVehicletype;

        if (this.currentUser.isbusnessuser && this.showRooms && this.showRooms.length > 0) {
          let showroomsCtrl = <HTMLSelectElement>document.getElementById("ddl_showrooms");

          if (showroomsCtrl.value.trim() != "") {
            this.vehicle.businessid = parseInt(showroomsCtrl.value.trim());

            let business = this.currentUser.businesses.find((b: any) => b.businessid == showroomsCtrl.value);

            this.vehicle.autolat = business.businesslat;
            this.vehicle.autolng = business.businesslng;
            this.vehicle.city = business.businesscity;
            this.vehicle.country = business.businesscountry;
            this.vehicle.autoaddress = business.businessaddress;
          }
          else
            this.errorsList.push("Select ShowRoom");
          // this.vehicle.businessid = null;
        } else
          this.vehicle.businessid = null;
        break;
      case "images":
        let mailageCtrl = <HTMLInputElement>document.getElementById("txt_mailage");
        let priceCtrl = <HTMLInputElement>document.getElementById("txt_price");

        if (mailageCtrl.value.trim() == "")
          this.errorsList.push("Enter Mailage");
        else
          this.vehicle.mileage = parseFloat(mailageCtrl.value.replaceAll(",", ""));
        if (priceCtrl.value.trim() == "")
          this.errorsList.push("Enter Price");
        else
          this.vehicle.price = parseFloat(priceCtrl.value.replaceAll(",", ""));

        let colorCtrl = <HTMLInputElement>document.querySelector('input[name="vcolor"]:checked');
        if (!this.coverFile)
          this.errorsList.push("Add Cover Photo");
        if (this.otherFiles.length == 0)
          this.errorsList.push("Add Photos");
        if (!colorCtrl)
          this.errorsList.push("Select Color");
        else
          this.vehicle.autocolour = parseInt(colorCtrl.value) + 1
        break;
      case "info":
        let desCtrl = <HTMLTextAreaElement>document.getElementById("txt_desctiption");
        let emailCtrl = <HTMLInputElement>document.getElementById("txt_email");
        let mobileCtrl = <HTMLInputElement>document.getElementById("txt_mobile");
        if (this.selectedYear == "")
          this.errorsList.push("Select Year");
        else
          this.vehicle.year = parseInt(this.selectedYear!);
        if (!this.makeSelectedItem)
          this.errorsList.push("Select Make");
        if (!this.modelSelectedItem)
          this.errorsList.push("Select Model");
        else {
          if (this.trimSelectedItem) {
            this.vehicle.makemodeltrimid = this.trimSelectedItem.id;
          } else
            this.vehicle.makemodeltrimid = this.modelSelectedItem.id;
        }
        if (desCtrl.value.trim() == "")
          this.errorsList.push("Enter Desctiption");
        else
          this.vehicle.autodescription = desCtrl.value.trim();
        if (mobileCtrl.value.trim() == "")
          this.errorsList.push("Enter Mobile Number");
        else
          this.vehicle.mobilenumber = mobileCtrl.value.trim()
        if (emailCtrl.value.trim() == "")
          this.errorsList.push("Enter Email Address");
        else
          this.vehicle.email = emailCtrl.value.trim()
        break;
      case "specification":
        let specCtrl = <HTMLInputElement>document.querySelector('input[name="specification"]:checked');
        let eTypeCtrl = <HTMLInputElement>document.querySelector('input[name="engineType"]:checked');

        if (!specCtrl)
          this.errorsList.push("Select Car Specification");
        else
          this.vehicle.gccspecs = parseInt(specCtrl.value) + 1
        if (!eTypeCtrl)
          this.errorsList.push("Select Engine Type");
        else
          this.vehicle.enginetype = parseInt(eTypeCtrl.value) + 1
        break;
      case "bodyStyle":
        let powerCtrl = <HTMLInputElement>document.getElementById("txt_power");
        let cylindersCtrl = <HTMLSelectElement>document.getElementById("ddl_cylinders");

        if (this.selectedVehicleBodytype == "" && (this.selectedVehicletype == "Car" || this.selectedVehicletype == "Heavy Vehicle")) {
          this.errorsList.push("Select Body Style");
        } else
          this.vehicle.bodystyle = this.selectedVehicleBodytype;

        if (powerCtrl.value.trim() != "")
          this.vehicle.hoursepower = parseInt(powerCtrl.value.trim());

        if (cylindersCtrl.value.trim() != "")
          this.vehicle.numberofcylinders = parseInt(cylindersCtrl.value.trim());
        break;
      case "location":
        let addrCtrl = <HTMLInputElement>document.getElementById("txt_address");
        if (!this.currentLocation)
          this.errorsList.push("Select Location");
        else {
          this.vehicle.autolat = this.latitude;
          this.vehicle.autolng = this.longitude;
          this.vehicle.city = this.city;
          this.vehicle.country = this.country;
        }
        if (addrCtrl.value.trim() == "")
          this.errorsList.push("Enter Address Details");
        else
          this.vehicle.autoaddress = addrCtrl.value;
        break;
      case "conditions":
        let bconditionsCtrl = <HTMLInputElement>document.querySelector('input[name="bconditions"]:checked');
        let mconditionsCtrl = <HTMLInputElement>document.querySelector('input[name="mconditions"]:checked');
        let warrantyCtrl = <HTMLInputElement>document.querySelector('input[name="warranty"]:checked');
        let steeringsideCtrl = <HTMLInputElement>document.querySelector('input[name="steeringside"]:checked');
        let transmissionCtrl = <HTMLInputElement>document.querySelector('input[name="transmission"]:checked');

        if (!bconditionsCtrl)
          this.errorsList.push("Select Body condition");
        else
          this.vehicle.bodycondition = parseInt(bconditionsCtrl.value) + 1
        if (!mconditionsCtrl)
          this.errorsList.push("Select Mechanical Condition");
        else
          this.vehicle.mechanicalcondition = parseInt(mconditionsCtrl.value) + 1
        if (!warrantyCtrl)
          this.errorsList.push("Select Warranty");
        else
          this.vehicle.warranty = parseInt(warrantyCtrl.value) + 1
        if (!steeringsideCtrl)
          this.errorsList.push("Select Steering Side");
        else
          this.vehicle.steeringside = parseInt(steeringsideCtrl.value) + 1
        if (!transmissionCtrl)
          this.errorsList.push("Select Transmission");
        else
          this.vehicle.transmission = parseInt(transmissionCtrl.value) + 1
        break;
      case "highlights":
        let doorsCtrl = <HTMLInputElement>document.getElementById("txt_doors");

        if (doorsCtrl.value.trim() != "")
          this.vehicle.doors = parseFloat(doorsCtrl.value);
        break;
    }

    this.isValid = this.errorsList.length == 0;

    if (this.isValid) {
      document.getElementById("tap_" + formname)?.setAttribute("completed", "true");
    } else {
      document.getElementById("tap_" + formname)?.removeAttribute("completed");
    }

    if (!this.isValid) {
      (function ($) {
        $("html, body").animate({ scrollTop: 0 }, 500);
        return false;
      })(jQuery);
    }

    return this.isValid;
  }

  tapChange(evt: any, currentTap: any, perviouseTap: any) {
    let tap = perviouseTap ? perviouseTap : currentTap;
    let isCompleted = document.getElementById("tap_" + tap)?.getAttribute("completed") == "true";

    if (this.isValid && isCompleted) {
      // Declare all variables
      var i, tabcontent, tablinks;

      // Get all elements with class="tabcontent" and hide them
      tabcontent = document.getElementsByClassName("tabcontent") as HTMLCollectionOf<HTMLDivElement>;
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }

      // Get all elements with class="tablinks" and remove the class "active"
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }

      // Show the current tab, and add an "active" class to the link that opened the tab
      document.getElementById(currentTap)!.style.display = "block";
      if (currentTap)
        document.getElementById('tap_' + currentTap)!.className += " active";
      // evt.currentTarget.className += " active";

      (function ($) {
        $("html, body").animate({ scrollTop: 0 }, 500);
        return false;
      })(jQuery);
    }

    return false;
  }

  isCompleted(tap: any) {
    let isCompleted = document.getElementById("tap_" + tap)?.getAttribute("completed") == "true";

    return isCompleted;
  }

  get() {
    this.apiloader.load().then(() => {
      if (navigator.geolocation) {
        let _self = this;
        navigator.geolocation.getCurrentPosition((position: any) => {
          if (position) {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            //this.getAddress = (this.latitude, this.longitude)
            // console.log(position)
            this.apiloader.load().then(() => {
              let geocoder = new google.maps.Geocoder;
              let latlng = {
                lat: this.latitude,
                lng: this.longitude
              };
              geocoder.geocode({
                'location': latlng
              }, function (results) {
                if (results && results[0]) {
                  let addrCtrl = <HTMLInputElement>document.getElementById("txt_address");

                  if (addrCtrl) {
                    _self.latitude = results[0].geometry.location.lat();
                    _self.longitude = results[0].geometry.location.lng();
                    _self.currentLocation = results[0].formatted_address;
                    addrCtrl.value = _self.currentLocation;
                    let splitBy = _self.currentLocation.includes('-') ? '-' : ','
                    let addr = _self.currentLocation.split(splitBy);
                    _self.city = addr[addr.length - 2].trim();
                    _self.country = addr[addr.length - 1].trim();
                  }
                  // console.log(results);
                } else {
                  console.log('Not found');
                }
              });

              if (this.searchElementRef) {
                let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
                  // componentRestrictions: this.componentRestrictions
                });

                autocomplete.setComponentRestrictions({
                  country: ["ae"]
                });

                autocomplete.addListener("place_changed", () => {
                  this.ngZone.run(() => {
                    //get the place result
                    let place: google.maps.places.PlaceResult = autocomplete.getPlace();

                    //verify result
                    if (place.geometry === undefined || place.geometry === null) {
                      return;
                    }

                    let addrCtrl = <HTMLInputElement>document.getElementById("txt_address");

                    //set latitude, longitude and zoom
                    _self.latitude = place.geometry.location!.lat();
                    _self.longitude = place.geometry.location!.lng();

                    _self.currentLocation = place.formatted_address;
                    addrCtrl.value = _self.currentLocation;
                    let splitBy = _self.currentLocation.includes('-') ? '-' : ','
                    let addr = _self.currentLocation.split(splitBy);
                    _self.city = addr[addr.length - 2].trim();
                    _self.country = addr[addr.length - 1].trim();
                    _self.zoom = 16;
                  });
                });
              }
            });
          }
        })
      }
    });
  }

  onMapClicked(event: any) {
    console.table(event);
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;

    let _self = this;

    this.apiloader.load().then(() => {
      let geocoder = new google.maps.Geocoder;
      let latlng = {
        lat: this.latitude,
        lng: this.longitude
      };
      geocoder.geocode({
        'location': latlng
      }, function (results) {
        if (results && results[0]) {
          let addrCtrl = <HTMLInputElement>document.getElementById("txt_address");

          _self.latitude = results[0].geometry.location.lat();
          _self.longitude = results[0].geometry.location.lng();
          _self.currentLocation = results[0].formatted_address;
          addrCtrl.value = _self.currentLocation;
          let splitBy = _self.currentLocation.includes('-') ? '-' : ','
          let addr = _self.currentLocation.split(splitBy);
          _self.city = addr[addr.length - 2].trim();
          _self.country = addr[addr.length - 1].trim();
          _self.zoom = 16;
          // console.log(results);
        } else {
          console.log('Not found');
        }
      });
    });
  }

  private readAndCompressFile(file: File): Promise<any> {
    // return new Observable(obs => {
    //   const reader = new FileReader();
    //   reader.onload = async () => {
    //     let result = await this.imageCompress.compressFile(reader.result as string, -1, undefined, undefined, 500, 300).then(
    //       r => {
    //         return r;
    //       }
    //     );

    //     const imageName = file.name;
    //     const imageFile = new File([result], imageName, { type: file.type });

    //     obs.next(imageFile);
    //     obs.complete();
    //   }
    //   reader.readAsDataURL(file);
    // });


    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (e) => {

        // let result = await this.imageCompress.compressFile(e!.target!.result as string, -1, 90, 90, 500, 300).then(
        //   r => {
        //     return r;
        //   }
        // );

        let result = await this.imageCompress.compressFile(e!.target!.result as string, -1).then(
          r => {
            return r;
          }
        );

        const imageName = file.name;

        const imageBlob = this.dataURItoBlob(result.split(',')[1]);
        const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
        resolve(imageFile);
      };
      reader.onerror = () => {
        reject(new Error('Unable to read..'));
      };
      reader.readAsDataURL(file);
    });
  }

  dataURItoBlob(dataURI: any) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }


  async getImageDimensions(file: File): Promise<{ width: number, height: number }> {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => {
        const width = img.width;
        const height = img.height;

        resolve({ width, height });
      };

      img.onerror = () => {
        reject('Error loading image');
      };

      img.src = URL.createObjectURL(file);
    });
  }

  async onChange(event: any, prifix: string, isCover: boolean = false) {
    let files = event.target.files;

    this.errorsList = [];

    if (!isCover && prifix == "_AutoPhoto" && files.length > 9) {
      event.preventDefault();
      this.errorsList.push("Max Photo is 9");
      event.target.value = "";
      return;
    }

    let dimensionsErrors = 0;
    for (const f of event.target.files) {
      const dimensions = await this.getImageDimensions(f);
      if (dimensions.width < dimensions.height) {
        dimensionsErrors++;
      }
    }

    if (dimensionsErrors > 0) {
      event.preventDefault();
      this.errorsList.push("One or more Images are not in landscape orientation");
      event.target.value = "";
      return;
    }

    // https://github.com/vijayrchoudhary/compressImageAngular
    var orientation = -1;
    let comFiles: any[] = [];
    for (const f of event.target.files) {
      const fileSizeInMB = f.size / (1024 * 1024); // Convert bytes to megabytes
      const maxFileSizeInMB = 0.5;

      if (fileSizeInMB > maxFileSizeInMB) {
        const imageFile = await this.readAndCompressFile(f);
        comFiles.push(imageFile);
      } else {
        comFiles.push(f);
      }

      // const imageFile = await this.readAndCompressFile(f);
      // comFiles.push(imageFile);

      // var reader = new FileReader();
      // reader.onload = async (e: any) => {
      //   let localUrl = e.target.result;
      //   // this.compressFile(this.localUrl,fileName)

      //   let result = await this.imageCompress.compressFile(localUrl, orientation, undefined, undefined, 500, 300).then(
      //     r => {
      //       return r;
      //     }
      //   );

      //   const imageName = f.name;
      //   const imageFile = new File([result], imageName, { type: f.type });

      //   comFiles.push(imageFile);
      // }
      // reader.readAsDataURL(f as any);
    }

    files = comFiles;

    let exist = this.files.find(f => f.fileName.includes(prifix));

    if (files.length > 0) {
      if (prifix == "_AutoPhoto") {
        // exist.files = files;
        if (isCover)
          this.coverFile = files[0];
        else
          this.otherFiles = files;
      }
    }
    else {
      if (prifix == "_AutoPhoto") {
        if (isCover)
          this.coverFile = null;
        else
          this.otherFiles = [];

        if (this.coverFile == null && this.otherFiles.length == 0) {
          let index = this.files.indexOf(exist);
          this.files.splice(index, 1);
        }
      } else {

        if (exist) {
          let index = this.files.indexOf(exist);
          this.files.splice(index, 1);
        }
      }
    }

    if (prifix == "_AutoPhoto") {
      let allFiles = this.coverFile ? [this.coverFile, ...this.otherFiles] : this.otherFiles;
      if (exist)
        exist.files = allFiles;
      else
        this.files!.push({
          "files": allFiles,
          "fileName": ("_" + this.vehicle.businessid + "_" + this.vehicle.userid + prifix).replaceAll(/undefined/g, "null")
        });
    } else
      if (exist)
        exist.files = files;
      else
        this.files!.push({
          "files": files,
          "fileName": ("_" + this.vehicle.businessid + "_" + this.vehicle.userid + prifix).replaceAll(/undefined/g, "null")
        });

    let currentFiles = this.files.find(f => f.fileName.includes(prifix));

    switch (prifix) {
      case "_AutoPhoto":
        for (let i = 1; i <= 10; i++) {
          (this.vehicle as any)["autophotopath" + i.toString()] = null;
        }

        if (currentFiles) {
          if (currentFiles.files.length == 1) {
            let file: File = currentFiles.files[0];
            var extIndex = file.name.lastIndexOf(".") + 1;

            var ext = file.name
              .substring(extIndex)
              .replaceAll(RegExp(/[^A-Za-z]/g), '');

            this.vehicle["autophotopath1"] = ("_" + this.vehicle.businessid + "_" + this.vehicle.userid + "_AutoPhoto." + ext).replaceAll(/undefined/g, "null");
          } else {
            for (let i = 0; i < currentFiles.files.length; i++) {
              let file: File = currentFiles.files[i];
              var extIndex = file.name.lastIndexOf(".") + 1;

              var ext = file.name
                .substring(extIndex)
                .replaceAll(RegExp(/[^A-Za-z]/g), '');

              switch (i) {
                case 0:
                  this.vehicle["autophotopath1"] = ("_" + this.vehicle.businessid + "_" + this.vehicle.userid + "_AutoPhoto_1." + ext).replaceAll(/undefined/g, "null");
                  break;
                case 1:
                  this.vehicle["autophotopath2"] = ("_" + this.vehicle.businessid + "_" + this.vehicle.userid + "_AutoPhoto_2." + ext).replaceAll(/undefined/g, "null");
                  break;
                case 2:
                  this.vehicle["autophotopath3"] = ("_" + this.vehicle.businessid + "_" + this.vehicle.userid + "_AutoPhoto_3." + ext).replaceAll(/undefined/g, "null");
                  break;
                case 3:
                  this.vehicle["autophotopath4"] = ("_" + this.vehicle.businessid + "_" + this.vehicle.userid + "_AutoPhoto_4." + ext).replaceAll(/undefined/g, "null");
                  break;
                case 4:
                  this.vehicle["autophotopath5"] = ("_" + this.vehicle.businessid + "_" + this.vehicle.userid + "_AutoPhoto_5." + ext).replaceAll(/undefined/g, "null");
                  break;
                case 5:
                  this.vehicle["autophotopath6"] = ("_" + this.vehicle.businessid + "_" + this.vehicle.userid + "_AutoPhoto_6." + ext).replaceAll(/undefined/g, "null");
                  break;
                case 6:
                  this.vehicle["autophotopath7"] = ("_" + this.vehicle.businessid + "_" + this.vehicle.userid + "_AutoPhoto_7." + ext).replaceAll(/undefined/g, "null");
                  break;
                case 7:
                  this.vehicle["autophotopath8"] = ("_" + this.vehicle.businessid + "_" + this.vehicle.userid + "_AutoPhoto_8." + ext).replaceAll(/undefined/g, "null");
                  break;
                case 8:
                  this.vehicle["autophotopath9"] = ("_" + this.vehicle.businessid + "_" + this.vehicle.userid + "_AutoPhoto_9." + ext).replaceAll(/undefined/g, "null");
                  break;
                case 9:
                  this.vehicle["autophotopath10"] = ("_" + this.vehicle.businessid + "_" + this.vehicle.userid + "_AutoPhoto_10." + ext).replaceAll(/undefined/g, "null");
                  break;
              }
            }
          }
        }
        break;
      case "_InspectionReport":
        if (currentFiles) {
          let file: File = currentFiles.files[0];
          var extIndex = file.name.lastIndexOf(".") + 1;

          var ext = file.name
            .substring(extIndex)
            .replaceAll(RegExp(/[^A-Za-z]/g), '');
          this.vehicle.inspectionreportpath = ("_" + this.vehicle.businessid + "_" + this.vehicle.userid + "_InspectionReport." + ext).replaceAll(/undefined/g, "null");
        } else
          this.vehicle.inspectionreportpath = null;
        break;
    }
  }

  removeDuplicates(arr: any[]) {
    return arr.reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      []
    );
  }

  // removeDuplicates(myArray, Prop) {
  //   return myArray.filter((obj, pos, arr) => {
  //     return arr.map(mapObj => mapObj[Prop]).indexOf(obj[Prop]) === pos;
  //   });
  // }

  async save() {
    if (this.isValid) {
      document.querySelector(".only-lock")?.removeAttribute("style");

      this.vehicle.autofeatures = this.selectedFeatures != null ? this.removeDuplicates(this.selectedFeatures!) : this.selectedFeatures;
      this.vehicle.chassisnumber = null;

      this.vehicle.addedfrom = 1;

      // console.log(this.vehicle);

      let auto = await this.autoService.addAutoAsync(this.vehicle);

      this.fileUploadService.awsUpload(this.files, auto.autoid).pipe(
        catchError((error: any) => {
          // Handle the error here
          console.error('An error occurred:', error);
          // You can also return a new observable or throw a custom error here if needed
          return throwError(() => 'Faild to upload Auto Images, try edit Auto and upload it again.');
        })
      ).subscribe(
        {
          next: (event: any) => {
            document.querySelector(".only-lock")?.setAttribute("style", "display: none;");

            window.location.href = "home";
          }, error: (error) => {
            document.querySelector(".only-lock")?.setAttribute("style", "display: none;");

            alert("Faild to upload Auto Images, try edit Auto and upload it again.");

            window.location.href = "home";
          }
        }
      );
    }
  }

  mapURL() {
    return this.sanitizer.bypassSecurityTrustResourceUrl("https://maps.google.com?q=25.192460, 55.278805&hl=es&z=14&output=embed");
  }

  safURL(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
